# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/

# $ ->
#   $('#user_avatar').on 'change', ->
#     files = undefined
#     reader = undefined
#     files = if ! !@files then @files else []
#     if !files.length or !window.FileReader
#       NProgress.start();
#       $('#image_form').submit();
#       return
#     if /^image/.test(files[0].type)
#       NProgress.start();
#       $('#image_form').submit()
#       return
#   return
#$ ->
#  $('#hospital_avatar').on 'change', ->
#    files = undefined
#    reader = undefined
#    files = if ! !@files then @files else []
#    if !files.length or !window.FileReader
#      NProgress.start();
#      $('#image_form').submit();
#      return
#    if /^image/.test(files[0].type)
#      NProgress.start();
#      $('#image_form').submit()
#      return
#  return

$('#image_load').click ->
  $('#user_avatar').click()
  return
updateImages = ->
  plant_query_id = $('#plant_query').attr('data-id')
  col_time = new Array
  $('.col-fixed-photo').each ->
    col_time.push $(this).attr('data-time')
    return
  after = Math.max.apply(Math, col_time)
  $.getScript '/plant_images?plant_query_id=' + plant_query_id + '&after=' + after
  setTimeout updateImages, 10000
  return

$ ->
  if $('#plant_query').length > 0
    setTimeout updateImages, 10000
  return