// JavaScript Document

//$("#ex6").slider({
//  tooltip: 'always'
//});
//$("#ex6").on("slide", function(slideEvt) {
//  $("#ex6SliderVal").text(slideEvt.value);
//});

global.$ = require("jquery")
import $ from 'jquery';


$(document).on('turbolinks:load', function() {
    $(".clndr-cls").datepicker({
        clearBtn: true,
        autoclose: true,
        minViewMode: "months",
        orientation: "bottom",
        format: 'mm-yyyy'
    });

    $('.aapc_spec_type').each(function(i, obj) {
        if ($(this).children().hasClass('image-on-small')){
            let list = $(this).children().text().trim().split('–')[1].trim().split(' ')[0];
            let id = $(this).children().find('input').attr('id');
            let aapc_list = '<li><span class="label label-info"> <a class="no_confirm_exit" href="javascript:void(0);"  onclick="remove_aapc_spec_type(this);"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span> </a></span> </li>';
            $('.aapc_spec_list ul').append(aapc_list);
            $('.aapc_spec_list ul li').last().find('span').first().prepend(list);
            $('.aapc_spec_list ul li').last().attr('id', id);
        }

    });

    $('.spec_type').each(function(i, obj) {
        if ($(this).children().hasClass('image-on-small')){
            let list= $(this).children().text()
            let id = $(this).children().find('input').attr('value');
            let aapc_list = '<li><span class="label label-info"> <a class="no_confirm_exit" href="javascript:void(0);"  onclick="remove_spec_type(this);"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span> </a></span> </li>';
            $('.spec_list ul').append(aapc_list);
            $('.spec_list ul li').last().find('span').first().prepend(list);
            $('.spec_list ul li').last().attr('id', id);
        }

    });

    // $('#user_patient_type').selectpicker({
    //     dropdownAlignRight: true,
    //     noneSelectedText: 'Select the Patient Types you are qualified for',
    //     selectedTextFormat: 'count > 1',
    //     width: '100%'
    // });

    // $('#hospital_facility_type_ids_').selectpicker({
    //     liveSearch: true,
    //     liveSearchNormalize: true,
    //     selectedTextFormat: 'count > 2'
    // });

    $("#provider_button").click(function () {
        if ($('#hospital_image').length > 0) {
            if ($('#hospital_image').valid()) {
                return
            }
            else {
                $('html, body').animate({
                    scrollTop: $("#hospital_image").offset().top
                }, 1000);
            }
        }
        else{
            return
        }
});

    if (!$('#user_time_zone').hasClass('select2-initialized')) {
      $('#user_time_zone').select2();
      $('#user_time_zone').addClass('select2-initialized');
    }

    $('.toggleCommon').click(function (){
      $(this).children('span.glyphicon').toggleClass('glyphicon-triangle-bottom');
    });

    $("#fixd-f-lb").click(function(){
        $("#fixd-f").show();
         $("#per-c").hide();
          $("#hrl").hide();
    });

    $(".yes-op").click(function(){
        $(".dt-mnt").show();
    });

    $(".no-op").click(function(){
        $(".dt-mnt").hide();
    });

    $('#work_summary').click(function (evt) {
        $(this).animate({height: '80'});
    });
    $('body').click(function (evt) {

        if (evt.target.id === "work_summary")
            return;
        else {
            $('#work_summary').animate({height: '40'})
        }
        //Do processing of click event here for every element except with id menu_content

    });
    $('#user_work_preference_attributes_ensure_accurate_reliable_coding').click(function (evt) {
        $(this).animate({height: '100'});
    });
    $('#hospital_summary').click(function (evt) {
        $(this).animate({height: '100'});
    });
    $('body').click(function (evt) {
        if ((evt.target.id == "user_work_preference_attributes_ensure_accurate_reliable_coding") || (evt.target.id == 'hospital_summary'))
            return;
        else {
            $('#user_work_preference_attributes_ensure_accurate_reliable_coding').animate({ height: '80' })
            if ($('#hospital_summary').height() != 66)
            {
                $('#hospital_summary').animate({ height: '80' })
            }
        }
        //Do processing of click event here for every element except with id menu_content
    })
    $('#id_text-2').click(function (evt) {
        $(this).animate({height: '100'})
    })
    $('body').click(function (evt) {

        if (evt.target.id == "id_text-2")
            return;
        else {
            $('#id_text-2').animate({height: '80'})
        }
        //Do processing of click event here for every element except with id menu_content

    })
    $(".image-off").click(function () {
        $(this).toggleClass("image-on");
    });
    $(".image-off-small").click(function(){
        $(this).toggleClass("image-on-small");
    });
});

$(function () {
    $('[data-toggle="tooltip"]').tooltip();
    $(window).on('shown.bs.modal', function() { 
        $('[data-toggle="tooltip"]').tooltip();
    });
});

$(document).on('turbolinks:load', function() {
    $('[data-toggle="tooltip"]').tooltip();
});

$(function () {
    $('.reg-hosp').tooltip({
        container: '.reg-hosp'
    });
    $('.reg-pwd').tooltip({
        container: '.reg-pwd'
    });
})


$(function () {
    $('.reg-usr').tooltip({
        container: '.reg-usr'
    });

    $('.poster').tooltip({
        container: '.poster'
    });
})
