import { HospitalJobPostingRules } from './hospital_job_posting_rules.js'

$(document).on('turbolinks:load', function() {

    $('#hospital_job_start_date, #hospital_job_end_date').tooltipster({
        trigger: 'track',
        onlyOne: false,
        position: 'top',
        animation: 'grow',
        updateAnimation: false,
        delay: 800
    });

    $('#hospital_job_no_of_chart, #hospital_job_fee_per_chart, #hospital_job_total_compensation, #hospital_job_job_description, #hospital_job_per_hour, #hospital_job_hour_per_week').tooltipster({
        trigger: 'track',
        onlyOne: false,
        position: 'right',
        animation: 'grow',
        updateAnimation: false,
        delay: 800,
        offsetX: 150,
        minWidth: 150,
        maxWidth: 250
    });

    $('#job_posting_form').submit(function (event) {
        let dirty = false;
        if ($('.job_chart_type_ul .image-on-small').length < 1 ){
            is_empty_type('.job_parent_chart_type',"Please choose at least one chart type");
            dirty = true;
        }
        if ($('#hospital_job_mode_of_pay').val() == 'Per Chart' && $('.job_chart_type_ul .image-on-small').not(".job_parent_chart_type").length > 1){
            is_empty_type('.job_parent_chart_type', "For per chart only single chart is allowed");
            dirty = true;
        }

        if ($('.job_coding_type_ul .image-on-small').length < 1 ){
            is_empty_type('.job_coding_type_chk', "Please choose at least one coding type");
            dirty = true;
        }

        if ($('.job_credential_ul .image-on-small').length < 1 ){
            if( $('.certification-panel-collapse.in').length < 3) {
                $('.certification-panel-collapse').first().parent().find('.panel-heading').find('a').removeClass('collapsed');
                $('.certification-panel-collapse').first().removeAttr("style");
                $('.certification-panel-collapse').first().addClass("in");
                $('.certification-panel-collapse').first().attr("aria-expanded","true");
                $('.certification-panel-collapse').first().parent().find('.panel-heading').find('span').addClass('glyphicon-triangle-bottom');
                is_empty_type('.job_credential_chk', "Please choose atleast one credential");
            }
            dirty = true;
        }

        else if($('#hospital_job_mode_of_pay').val() === ''){
            $('#rb_hourly').tooltipster({
                trigger: 'track',
                onlyOne: false,
                position: 'top',
                animation: 'grow',
                updateAnimation: false,
                delay: 800,
                offsetX: 100,
                minWidth: 150,
                maxWidth: 250
            });
            $('#rb_hourly').tooltipster('update', "Please choose your mode of job compensation");
            $('#rb_hourly').tooltipster('show');
            dirty = true;
        }
        else if ($('#rb_full_time').is(':checked') === true) {
            if($('#hospital_job_start_date').val() === ''){
                dirty = true;
            }
        }
        else if ($('#rb_part_time').is(':checked') === true){
            if($('#hospital_job_start_date').val() === '' || $('#hospital_job_end_date').val() === ''){
                dirty = true;
            }
        }
        else if ($('#rb_perchart').is(':checked') === true){
            if($('#hospital_job_start_date').val() === '' || $('#hospital_job_end_date').val() === ''
                || $('#hospital_job_no_of_chart').val() === '' || $('#hospital_job_fee_per_chart').val() === ''){
                dirty = true;
            }
        }
        else if ($('#rb_fixedfee').is(':checked') === true){
            if($('#hospital_job_start_date').val() === '' || $('#hospital_job_end_date').val() === ''
                || $('#hospital_job_no_of_chart').val() === '' || $('#hospital_job_total_compensation').val() === ''){
                dirty = true;
            }
        }

        if (dirty === true)
          return false;

        if ($(".workload_lbl").is(":visible") && !$("#customConfirmationDialog").is(":visible")){
          var start_new_date =  get_new_date($("#hospital_job_start_date").val());
          var end_new_date = get_new_date($("#hospital_job_end_date").val());

          var daysDiff = Math.ceil((end_new_date - start_new_date + 1) / (1000 * 60 * 60 * 24));

          var message = "We detected this job may request a daily chart productivity level greater than current industry standards, this may not be feasible. What would you like to do?";

          var html = $("#customConfirmationDialog");

          if ($("#customConfirmationDialog").length == 0){
                html = $("<div class=\"modal\" id=\"customConfirmationDialog\">\n   <div class=\"modal-dialog\" style=\"width: 450px;\">\n       <div class=\"modal-content\">\n          <div class=\"modal-header\">\n            <a class=\"close\" data-dismiss=\"modal\">×</a>\n            <h3 class=\"modal-title\" style=\"font-size: 20px\">Confirm Message</h3>\n          </div>\n          <div class=\"modal-body\">\n            <p style=\"font-size: 16px;text-align: left\">" + message + "</p>\n          </div>\n          <div class=\"modal-footer\">\n     <div class='col-md-12'>        <a data-toggle='tooltip' data-placement='bottom' title='Go back to edit Job' data-dismiss=\"modal\" style=\"line-height:35px; color:white\" class=\"col-md-3 btn btm-sm-pri\">Edit Job</a>    <a data-toggle='tooltip' disabled data-placement='bottom' title='Offer to multiple Coders' data-dismiss=\"modal\" style=\"line-height:35px; color:white\" class=\"col-md-4  btn btm-sm-pri-green btn-primary multijob\">Split Job</a>       <a data-toggle='tooltip' data-placement='bottom' title='View Job for Posting' data-dismiss=\"modal\" style=\"line-height:35px; color:white\" class=\"col-md-4  btn btm-sm-pri-green btn-primary confirm\">Continue</a> </div>\n          </div>\n      </div>\n   </div>\n</div>");
                html.find('[data-toggle="tooltip"]').tooltip();
          }

          html.modal();

          $('#customConfirmationDialog .confirm').on('click', function() {
            $('#job_posting_form').submit();
          });

          return false;
        }
    });

    $("#job_posting_form").validate({
        rules: {
            "hospital_job[end_date]" : {
                required: true
            },
            "hospital_job[start_date]" :
            {
                required: true
            }
        },
        messages: {
            "hospital_job[start_date]" :{
                required: "Please enter start date"
            },
            "hospital_job[end_date]" :{
                required: "Please enter end date"
            }
        },
        errorPlacement: function (error, element) {
            if (element.val()[element.val().length-1] === '.' &&  (element.attr('id') === 'hospital_job_fee_per_chart' || element.attr('id') === 'hospital_job_per_hour' || element.attr('id') === 'hospital_job_total_compensation' )) {
                $(element).tooltipster('hide');
            }
            else{
                $(element).tooltipster('update', $(error).text());
                $(element).tooltipster('show');
            }
        },
        success: function (label, element) {
            $(element).tooltipster('hide');
        }
    });

    $("#coder_experience").slider({
        id: "slider12b",
        tooltip: 'always'
    });

    $("#select_template").select2({
        placeholder: "Select",
        allowClear: true
    });

    $('.job_description').keyup(function () {
        var value = $(this).val();
        var len = value.replace(/\r\n/g, '~~').replace(/\n/g, '~~').length;
        var left = 250 - len;
        if (left < 0) {
            left = 0;
        }
        $('.chr-lft').text(left+ ' characters left');
    });

    if ($('#job_posting_form input:radio[name="hospital_job[location]"]:checked').val() === undefined) {
        $('#hospital_job_location_remote').prop('checked', true);
    }
    else if ($('#job_posting_form input:radio[name="hospital_job[location]"]:checked').val() !== "Remote") {
        $(".pay-long-distance").show(100);
    }

    if ($('#job_posting_form input:radio[name="hospital_job[job_type]"]:checked').val() === undefined) {
        $('#hospital_job_job_type_standard').prop('checked', true);
    }
    if ($('#job_posting_form input:radio[name="hospital_job[price_negotiable]"]:checked').val() === undefined) {
        $('#hospital_job_price_negotiable_true').prop('checked', true);
    }

    // if ($('.job_coding_type_ul .image-on-small').length < 1 ){
        // $('.job_coding_type_chk:eq(1)').addClass('image-on-small');
        // $('.job_coding_type_chk:eq(1)').next().find('input').prop("checked",true);
    // }

    $("#hourly-lb").on('click',function (){
        clear_val_job_compensation();
        job_compensation_hourly();
        //$('#hospital_job_no_of_hours_in_week').val('');
        //job_compensation_full_time();
        job_compensation_part_time();
        $('#rb_part_time').prop('checked', true);
        $("#job_posting_form .tooltipstered").tooltipster('hide');
    });

    $("#lbl_part_time").on('click',function (){
        clear_val_job_compensation();
        job_compensation_part_time();
        $("#job_posting_form .tooltipstered").tooltipster('hide');

    });

    $("#lbl_full_time").on('click',function (){
        clear_val_job_compensation();
        $('#hospital_job_no_of_hours_in_week').val('');
        job_compensation_full_time();
        $("#job_posting_form .tooltipstered").tooltipster('hide');
    });

    $("#lbl_per_chart").on('click',function (){
        clear_val_job_compensation();
        job_compensation_chart();
        $("#job_posting_form .tooltipstered").tooltipster('hide');
    });

    $("#rb_fixedfee").on('click',function (){
        clear_val_job_compensation();
        job_compensation_fixed();
        $("#job_posting_form .tooltipstered").tooltipster('hide');
    });

    $("#save_job_template").click(function() {
        $("#job-template-name").show();
        var first_letter = '';
        let hospital_name = ($("#hospital_name").val().split(' '));
        $.each(hospital_name, function() {
          first_letter +=   this.substring(0,1);
        });
        if ($("#hospital_job_template_name").val() === '')
            $("#hospital_job_template_name").val(first_letter);
        hospital_name = $("#hospital_job_template_name").val()
        if ($("#hospital_job_template_name").val() !== '' && $("#hospital_job_template_name").val() !== first_letter){
            window.location.href = "/hospital_job_posting/"+$('#hospital_job_id').val()+"/save_job_template"+'?name='+ encodeURIComponent(hospital_name)
        }
    });

    $('#hospital_job_template_name').on('keyup keydown',function () {
        if( $(this).val().trim() == '')
            $(this).val($(this).val().trim());
    });

    $('#hospital_job_hour_per_week, #hospital_job_per_hour').on('keyup keydown',function () {
        if ($('#rb_full_time').is(':checked') === true) {
            if ($.isNumeric($(this).val())) {
                var start_new_date =  get_new_date($("#hospital_job_start_date").val());
                var end_new_date = get_new_date($("#hospital_job_end_date").val());
                var weekDiff = hourly_diff(start_new_date, end_new_date, 'compensation');
                let num = parseFloat(weekDiff * $('#hospital_job_per_hour').val() * $('#hospital_job_hour_per_week').val())
                let value = num.toFixed(2);
                if (isNaN(value))
                    $('#hospital_job_total_compensation').val('0.00');
                else
                    $('#hospital_job_total_compensation').val(value);
            }
            else {
                $(this).val($(this).val().substr(0, $(this).val().length - 1));
                $('#hospital_job_total_compensation').val('0.00');
            }
        }
    });

    $('#hospital_job_per_hour').on('keyup keydown',function () {
        if ($('#rb_part_time').is(':checked') === true) {
            if ($.isNumeric($(this).val())) {
                var start_new_date =  get_new_date($("#hospital_job_start_date").val());
                var end_new_date = get_new_date($("#hospital_job_end_date").val());
                var weekDiff = hourly_diff(start_new_date, end_new_date, 'compensation');
                let num = parseFloat(weekDiff * $('#hospital_job_per_hour').val() * $('#hospital_job_no_of_hours_in_week').val())
                let value = num.toFixed(2);
                if (isNaN(value))
                    $('#hospital_job_total_compensation').val('0.00');
                else
                    $('#hospital_job_total_compensation').val(value);
            }
            else {
                $(this).val($(this).val().substr(0, $(this).val().length - 1));
                $('#hospital_job_total_compensation').val('0.00');
            }
        }
    });

    $('#hospital_job_no_of_hours_in_week').change(function () {
        if ($('#rb_part_time').is(':checked') === true) {
            if ($.isNumeric($(this).val())) {
                var start_new_date =  get_new_date($("#hospital_job_start_date").val());
                var end_new_date = get_new_date($("#hospital_job_end_date").val());
                var weekDiff = hourly_diff(start_new_date, end_new_date, 'compensation');
                let num = parseFloat(weekDiff * $('#hospital_job_per_hour').val() * $('#hospital_job_no_of_hours_in_week').val())
                let value = num.toFixed(2);
                if (isNaN(value))
                    $('#hospital_job_total_compensation').val('0.00');
                else
                    $('#hospital_job_total_compensation').val(value);
            }
            else {
                $(this).val($(this).val().substr(0, $(this).val().length - 1));
                $('#hospital_job_total_compensation').val('0.00');
            }
        }
    });

    $('#hospital_job_no_of_chart, #hospital_job_fee_per_chart').on('keyup keydown',function () {
        if ($('#rb_perchart').is(':checked') === true ) {
            if ($.isNumeric($(this).val())) {
                let num = parseFloat($('#hospital_job_no_of_chart').val() * $('#hospital_job_fee_per_chart').val())
                let value = num.toFixed(2);
                $('#hospital_job_total_compensation').val(value);
            }
            else {
                $(this).val($(this).val().substr(0, $(this).val().length - 1));
                $('#hospital_job_total_compensation').val('0.00');
            }
        }

        showSplitJobOptionIfNecessary();
    });

    $('#hospital_job_no_of_chart, #hospital_job_total_compensation').on('keyup keydown', function () {
        if ($('#rb_fixedfee').is(':checked') === true) {
            if ($.isNumeric($(this).val())) {
                let num = parseFloat($('#hospital_job_total_compensation').val() / $('#hospital_job_no_of_chart').val())
                let value = num.toFixed(2);
                if($.isNumeric(value))
                    $('#hospital_job_fee_per_chart').val(value);
                else
                    $('#hospital_job_fee_per_chart').val('0.00');
            }
            else {
                $(this).val($(this).val().substr(0, $(this).val().length - 1));
                $('#hospital_job_fee_per_chart').val('0.00');
            }
        }
    });

    $(document).on( "change", "#hospital_job_end_date", function() {
        $(this).datepicker('hide');
    });
    $(document).on( "click", "#hospital_job_start_date, #hospital_job_end_date", function() {
        $(this).datepicker('show');
    });

    $('#hospital_job_start_date').change(function () {
        $("#hospital_job_start_date").val($("#hospital_job_start_date").val().replace(/\//g, '-'));
        let current_date = new Date();
        var start_new_date =  get_new_date($("#hospital_job_start_date").val());
        var end_new_date = get_new_date($("#hospital_job_end_date").val());
        $('#hospital_job_start_date').datepicker('hide');
        let dayDiffernce = days_diff(get_new_date($("#critical_start_date").val()), start_new_date);
        if (dayDiffernce < 1 ){
            make_job_critical()
        }
        else{
          make_job_standard()
        }
        if ($('#rb_part_time').is(':checked') === true || $('#rb_full_time').is(':checked') === true){
            var no_of_days = $('#rb_full_time').is(':checked') === true ? 0 : 0
            var hour_week = $('#rb_full_time').is(':checked') === true ? $('#hospital_job_hour_per_week').val() : $('#hospital_job_no_of_hours_in_week').val()
            if ( $("#hospital_job_end_date").val() != "") {
                var weekDiff = hourly_diff(start_new_date, end_new_date, 'compensation');
                let num = parseFloat(weekDiff * $('#hospital_job_per_hour').val() * hour_week)
                let value = num.toFixed(2);
                if (isNaN(value))
                    $('#hospital_job_total_compensation').val('0.00');
                else
                    $('#hospital_job_total_compensation').val(value);
            }
        }
        $("#hospital_job_end_date").datepicker("update",$('#hospital_job_end_date').val())
        $("#hospital_job_end_date").focus();

        showSplitJobOptionIfNecessary()
    });

    $('#SetDesignationManually').change(function() {
      if(this.checked) {
        make_job_critical()
      }
      else{
        make_job_standard()
      }
    });

    $('#hospital_job_end_date').change(function () {
        if ($('#rb_part_time').is(':checked') === true || $('#rb_full_time').is(':checked') === true){
            var hour_week = $('#rb_full_time').is(':checked') === true ? $('#hospital_job_hour_per_week').val() : $('#hospital_job_no_of_hours_in_week').val()
            var start_new_date =  get_new_date($("#hospital_job_start_date").val());
            var end_new_date = get_new_date($("#hospital_job_end_date").val());

            //if ( $("#hospital_job_start_date").val() != "") {
            //    var start_new_date =  get_new_date($("#hospital_job_start_date").val());
            //    var end_new_date = get_new_date($("#hospital_job_end_date").val());
            //    current_date = new Date();
            //    var weekDiffernce = hourly_diff(current_date, start_new_date, 'job_type');
            //    if (weekDiffernce < 3 ){
            //        $('#hospital_job_job_type').val('Critical');
            //        $('.job_type_lbl').text('Critical');
            //        $('.job_type_lbl').removeClass('label-success');
            //        $('.job_type_lbl').addClass('label-danger');
            //    }
            //    else{
            //        $('#hospital_job_job_type').val('Standard');
            //        $('.job_type_lbl').text('Standard');
            //        $('.job_type_lbl').addClass('label-success');
            //        $('.job_type_lbl').removeClass('label-danger');
            //    }
                let weekDiff = hourly_diff(start_new_date, end_new_date, 'compensation');
                let num = parseFloat(weekDiff * $('#hospital_job_per_hour').val() * hour_week)
                let value = num.toFixed(2);
                if (isNaN(value))
                    $('#hospital_job_total_compensation').val('0.00');
                else
                    $('#hospital_job_total_compensation').val(value);
            }
        //}

      showSplitJobOptionIfNecessary()

    });

    $('#hospital_job_fee_per_chart, #hospital_job_per_hour, #hospital_job_total_compensation').on('change',function () {
        if ($.isNumeric($(this).val()) && !$(this).hasClass('error')) {
            let num = parseFloat($(this).val())
            $(this).val(num.toFixed(2));
        }
        else if($.isNumeric($(this).val()) && $(this).val()[$(this).val().length-1] === '.'){
            let num = parseFloat($(this).val())
            $(this).val(num.toFixed(2));
        }
    });

    if ($('#hospital_job_mode_of_pay').val() === "Full Time") {
        job_compensation_hourly();
        $('#hospital_job_mode_of_pay').val('Full Time');
        $('#hospital_job_hour_per_week').parent().show();
        $('#rb_full_time').prop('checked', true);
        $('#hospital_job_total_compensation').attr('readonly', true);
        $("#hospital_job_end_date").attr("readonly", false);
        $("#hospital_job_end_date").rules('remove');
        $('#hospital_job_hour_per_week').rules("add", {
            required: true,
            min: 32,
            max: 40,
            digits: true,
            messages: {
                required: "Please enter hour per week",
                min: 'Minimum value for hours per week should be 32',
                max: 'Maximum value for hours per week should be 40',
                digits: 'Hours per week should be in digit'
            }
        });
    }
    else if ($('#hospital_job_mode_of_pay').val() === "Part Time") {
        job_compensation_hourly();
        $('#hospital_job_mode_of_pay').val('Part Time');
        $('#no_of_hours_in_week_div').show();
        $('#hospital_job_hour_per_week').parent().hide();
        $('#rb_part_time').prop('checked', true);
        $('#no_of_hours_in_week_div').insertBefore('#hours_div');
    }
    else if ($('#hospital_job_mode_of_pay').val() === "Per Chart") {
        $('#rb_perchart').prop('checked', true);
        job_compensation_chart();
    }
    else if ($('#hospital_job_mode_of_pay').val() === "Fixed Fee") {
        $('#rb_fixedfee').prop('checked', true);
        job_compensation_fixed();
    }
    //else if ($('#hospital_job_mode_of_pay').val() === "") {
    //    job_compensation_hourly();
    //    $('#hospital_job_mode_of_pay').val('Part Time');
    //    $('#no_of_hours_in_week_div').show();
    //    $('#hospital_job_hour_per_week').parent().hide();
    //    $('#rb_part_time').prop('checked', true);
    //    $('#no_of_hours_in_week_div').insertBefore('#hours_div');
    //}

    $('.list_inline_ext .image-off-small').click(function () {
        if($(this).hasClass("image-on-small")) {
            var list_ul = $(this).parent().parent().parent().parent().parent().find('.highlight_list_show').find('ul');
            var list = '<li><span class="label label-info"> <a href="javascript:void(0);" onclick="remove_job_highlight_details(this);"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span> </a></span> </li>';
            list_ul.append(list);
            var text = $(this).next().text().replace(/TM/g, "");
            var id = $(this).attr('id');
            list_ul.find('li').last().find('span').first().prepend(text);
            list_ul.find('li').last().attr('id', id);
        }
        else if ($(this).hasClass("image-off-small")){
            list_ul= $(this).parent().parent().parent().parent().parent().find('.highlight_list_show').find('ul');
            id = $(this).attr('id');
            $('.highlight_list_show #'+ id).remove();
        }
    });

    $('#job_posting_form .job_details_inner_div').find('li').each(function(i, obj) {
        if ($(this).children().hasClass('image-on-small')){
            var list_ul = $(this).parent().parent().parent().parent().find('.highlight_list_show').find('ul');
            var list = '<li><span class="label label-info"> <a href="javascript:void(0);" onclick="remove_job_highlight_details(this);"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span> </a></span> </li>';
            list_ul.append(list);
            var text = $(this).children().text();
            var id = $(this).children().first().attr('id');
            list_ul.find('li').last().find('span').first().prepend(text);
            list_ul.find('li').last().attr('id', id);
        }
    });

    if($('#hospital_job_job_type').val() == "Standard"){
      make_job_standard()
    }
    else if ($('#hospital_job_job_type').val() == "Critical"){
      make_job_critical()
    }
    else if ($('#hospital_job_job_type').val() == ""){
        $('#hospital_job_job_type').val('Standard');
        $('.job_type_lbl').text('Standard');
    }

    $('.job_parent_chart_type').click(function() {
        if($(this).hasClass("image-on-small")) {
            $(this).parent().next().find('.job_child_chart_type').show(300);
            $(this).parent().next().find('.job_child_chart_type').find('.image-off-small').first().addClass('image-on-small');
            $(this).parent().next().find('.job_child_chart_type').find('input').first().prop("checked",true);
            $(this).parent().next().find('.job_child_chart_type').find('input').first().trigger("change");
        }
        else {
            $(this).parent().next().find('.job_child_chart_type').find('.image-on-small').removeClass('image-on-small');
            $(this).parent().next().find('.job_child_chart_type').find('input').prop("checked",false);
            $(this).parent().next().find('.job_child_chart_type').find('input').trigger("change");
            $(this).parent().next().find('.job_child_chart_type').hide(300);
        }
    });
    $('.job_child_chart_type .image-off-small').click(function(){
       if ($(this).parent().parent().parent().find('.image-on-small').length == 0)
           $(this).parent().parent().parent().prev().find('.image-on-small').removeClass('image-on-small');
       else
           $(this).parent().parent().parent().prev().find('.image-off-small').addClass('image-on-small');
    });
    $('.job_child_chart_type .image-on-small').parent().parent().parent().find('.job_child_chart_type').show()
    $('.job_child_chart_type .image-on-small').parent().prev().find('.image-off-small').addClass('image-on-small');
    $('.job_child_chart_type:visible').parent().prev().find('.image-off-small').addClass('image-on-small');

    var $changed_form = false;

    $('.job_chart_type_chk_box').click(function(){
        if ($('.job_parent_chart_type').first().hasClass('tooltipstered') && $('#hospital_job_mode_of_pay').val() == 'Per Chart' && $('.job_chart_type_ul .image-on-small').not(".job_parent_chart_type").length == 1)
            $('.job_parent_chart_type').first().tooltipster('hide');
        else if($('.job_parent_chart_type').first().hasClass('tooltipstered') && $(this).hasClass("image-on-small"))
            $('.job_parent_chart_type').first().tooltipster('hide');
        if ($('.job_chart_type_ul .image-on-small').length < 1 )
            is_empty_type('.job_parent_chart_type', "Please choose atleast one chart type");
        else if ($('#hospital_job_mode_of_pay').val() == 'Per Chart' && $('.job_chart_type_ul .image-on-small').not(".job_parent_chart_type").length > 1)
            is_empty_type('.job_parent_chart_type', "For per chart only single chart is allowed");
    });

    $('.job_coding_type_chk').click(function(){
        if($('.job_coding_type_chk').first().hasClass('tooltipstered') && $(this).hasClass("image-on-small"))
            $('.job_coding_type_chk').first().tooltipster('hide');
        if ($('.job_coding_type_ul .image-on-small').length < 1 )
            is_empty_type('.job_coding_type_chk', "Please choose atleast one coding type");
    });

    $('.job_credential_chk').click(function(){
        if( $('.job_credential_ul').find('.tooltipstered').length >= 1 && $(this).hasClass("image-on-small"))
            $('.job_credential_ul').find('.tooltipstered').tooltipster('hide');
        if ($('.job_credential_ul .image-on-small').length < 1 )
            is_empty_type('.job_credential_chk', "Please choose atleast one credential");
    });

    $("#job_posting_form :input").change(function () {
        if($(this).attr('id') !== 'select_template' && $(this).attr('class') !== 'select2-input select2-focused')
            $changed_form = true;
    });
    $('#select_template').on('click', function(e) {
        if ($changed_form == false)
            window.onbeforeunload = null;
    });

    $('#select_template').on('change', function(e){
        if ($changed_form == false)
            window.onbeforeunload = null;
        var id = $(this).val() !== '' ? $(this).val() : null ;
        window.location.href = "/hospital_job_posting/"+id+'/job_template';
    });

    $('#job_posting_form').submit(function(e){
        let current_date = new Date();
        var start_new_date =  get_new_date($("#hospital_job_start_date").val());
        //var weekDiffernce = hourly_diff(current_date, start_new_date, 'job_type');
        if ( $("#SetDesignationManually").is(':checked') ){
            $('#hospital_job_job_type').val('Critical');
        }
        else{
            $('#hospital_job_job_type').val('Standard');
        }
    });

    $("#chkMultijob").click(function(e){
      //var checked = $("#chkMultijob").is(":checked");
      //
      //if (checked){
      //  $("#multijobAmount").show();
      //}else{
      //  $("#multijobAmount").hide();
      //}
    });

    HospitalJobPostingRules.runAllValidations();
});

function make_job_critical() {
  $(".manual_designation").css("padding-left", "111px");
  $(".manual_designation").attr("title", "Click here to make job Standard");
  $("#SetDesignationManually").prop('checked', true);
  $('.critical_lbl').show();
  $('.standard_lbl').hide();
}

function make_job_standard() {
  $(".manual_designation").css("padding-left", "99px");
  $(".manual_designation").attr("title", "Click here to make job Critical")
  $("#SetDesignationManually").prop('checked', false);
  $('.critical_lbl').hide();
  $('.standard_lbl').show();
}

function get_coding_type_id(coding_type_name){
  var coding_type_json = JSON.parse($("#coding_types_json").val());

  var id;

  coding_type_json.forEach(function(coding_type){
    if(coding_type[2] == coding_type_name){
      id = coding_type[0]; //return id
    }
  });

  return id;
}

window.get_certification_id = function get_certification_id(certification_name){
  var certifications_json = $("#certifications_json").val();
  var certification_data = null;
  var id;
  
  if (certifications_json && certifications_json !== "nil") {
    certification_data = JSON.parse(certifications_json);
    certification_data.forEach(function(certification){
      if(certification[2] == certification_name){
        id = certification[0]; //return id
      }
    });
  }
  


  return id;
}

window.get_patient_type_id = function get_patient_type_id(patient_type_name){
  var patient_type_json = JSON.parse($("#patient_types_json").val());

  var id;

  patient_type_json.forEach(function(patient_type){
    if(patient_type[2] == patient_type_name){
      id = patient_type[0]; //return id
    }
  });

  return id;
}

window.get_speciality_id = function get_speciality_id(speciality_name){
  var speciality_json = JSON.parse($("#aapc_speciality_types_json").val());

  var id;

  speciality_json.forEach(function(speciality){
    if(speciality[2] == speciality_name){
      id = speciality[0]; //return id
    }
  });

  return id;
}


window.is_coding_type_checked = function is_coding_type_checked(coding_type_name){
  var coding_type_id = get_coding_type_id(coding_type_name);

  return $("#specialcoding_type_" + coding_type_id +" input").is(":checked");
}

window.is_certification_checked = function is_certification_checked(certification_name){
  var certification_id = get_certification_id(certification_name);

  return $("#specialcertification_type_" + certification_id +" input").is(":checked");
}

window.is_patient_type_checked = function is_patient_type_checked(patient_type_name){
  var patient_type_id = get_patient_type_id(patient_type_name);

  return $("#specialpatient_type_" + patient_type_id +" input").is(":checked");
}

window.is_speciality_checked = function is_speciality_checked(speciality_name){
  var speciality_id = get_speciality_id(speciality_name);

  return $("#specialaapc_speciality_type_" + speciality_id +" input").is(":checked");
}


window.job_work_location = function job_work_location(status){
    $('#job_posting_form #hospital_job_location').val(status);
    if (status !== "Remote") {
        $(".pay-long-distance").show(100);
        if($('#job_posting_form input:radio[name="hospital_job[pay_for_long_distance]"]:checked').length == 0)
        $('#hospital_job_pay_for_long_distance_false').prop('checked', true);
    }
    else{
        $(".pay-long-distance").hide(200);
        $('#hospital_job_pay_for_long_distance_false').prop('checked', true);
    }
}

window.clear_val_job_compensation = function clear_val_job_compensation(){
    $('#job_compensation_elements').find('input').each( function( ) {
        if($(this).attr('id') !== 'hospital_job_start_date' && $(this).attr('id') !== 'hospital_job_end_date')
            $(this).val("");
    });
    var end_date = new Date($('#hospital_job_start_date').val());
    end_date.setDate(end_date.getDate() +  0);
    $('#hospital_job_start_date').datepicker('update');
    $('#hospital_job_end_date').datepicker('update');
}

window.job_compensation_full_time = function job_compensation_full_time(){
    $('#no_of_hours_in_week_div').hide();
    $('#hospital_job_hour_per_week').parent().show();
    $('#hospital_job_mode_of_pay').val('Full Time');
   // $('#hospital_job_hour_per_week').val(40);
    $("#hospital_job_end_date").rules('remove');
    $('#hospital_job_total_compensation').attr('readonly', true);
    $('#hospital_job_fee_per_chart').attr('readonly', false);
    $("#hospital_job_end_date").attr("readonly", false);
    $('#hospital_job_no_of_hours_in_week').val('');
    $('#hospital_job_hour_per_week').rules("add", {
        required: true,
        min: 32,
        max: 40,
        digits: true,
        messages: {
            required: "Please enter hour per week",
            min: 'Minimum value for hours per week should be 32',
            max: 'Maximum value for hours per week should be 40',
            digits: 'Hours per week should be in digit'
        }
    });
}

window.job_compensation_part_time = function job_compensation_part_time(){
    $('#hospital_job_hour_per_week').parent().hide();
    $('#no_of_hours_in_week_div').show();
    $('#no_of_hours_in_week_div').insertBefore('#hours_div');
    $('#hospital_job_mode_of_pay').val('Part Time');
    $('#hospital_job_no_of_hours_in_week').val(20);
    $("#hospital_job_end_date").rules("add", {
        required: true,
        messages: {
            required: "Please enter end date"
        }
    });
    $('#hospital_job_total_compensation').attr('readonly', true);
    $('#hospital_job_fee_per_chart').attr('readonly', false);
    $("#hospital_job_end_date").attr("readonly", true);
    $('#hospital_job_hour_per_week').val('');
}

window.job_compensation_hourly = function job_compensation_hourly(){
    $("#dslp_hourly_div").show();
    $('.hourly-input').show();
    $('#hours_div').show();
    if ($('#dslp_hourly_div .hourly_elm').children().length === 0)
        $('#dslp_hourly_div .hourly_elm').append($('#job_compensation_elements').show());
    $('#no_of_hours_in_week_div').hide();
    $('#chart_div').hide();
    $('#hospital_job_no_of_chart').val('');
    $('#hospital_job_fee_per_chart').val('');
    $('#compensation_div').insertAfter('#chart_div');
    $('#compensation_div .hr-ph').insertBefore('#compensation_inner_div');
    $("#hospital_job_no_of_chart").rules('remove');
    $("#hospital_job_fee_per_chart").rules('remove');
    $("#hospital_job_total_compensation").rules('remove');
    $('#hospital_job_total_compensation').attr('readonly', true);
    $('#hospital_job_fee_per_chart').attr('readonly', false);
    $("#hospital_job_per_hour").rules("add", {
        required: true,
        two_dec_with_three_digit: true,
        messages: {
            required: "Please enter per hour",
            two_dec_with_three_digit: 'Payment per hour can contain maximum of three digit with two decimal'
        }
    });
    $("#hospital_job_end_date").attr("readonly", true);
    $('#rb_hourly').prop('checked', true);
}

window.job_compensation_chart = function job_compensation_chart(){
    $("#dslp_hourly_div").hide();
    $('.hourly-input').hide();

    if ($('#perchart_div .chart_elm').children().length === 0)
        $('#perchart_div .chart_elm').append($('#job_compensation_elements').show());
    $('#no_of_hours_in_week_div').hide();
    $('#hospital_job_no_of_hours_in_week').val('');
    $('#hours_div').hide();
    $('#chart_div').show();
    $('#hospital_job_hour_per_week').val('');
    $('#hospital_job_per_hour').val('');
    $('#hospital_job_mode_of_pay').val('Per Chart');
    $('#compensation_div').insertAfter('#chart_div');
    $('#compensation_div .hr-ph').insertBefore('#compensation_inner_div');
    $("#hospital_job_no_of_chart").rules("add", {
        required: true,
        four_digit: true,
        messages: {
            required: "Please enter no of chart",
            four_digit: 'No of chart can contain maximum of four digit'
        }
    });
    $("#hospital_job_fee_per_chart").rules("add", {
        required: true,
        two_dec_with_three_digit: true,
        messages: {
            required: "Please enter fee per chart",
            two_dec_with_three_digit: 'Fee per chart can contain maximum of three digit with two decimal'
        }
    });
    $("#hospital_job_per_hour").rules('remove');
    $("#hospital_job_total_compensation").rules('remove');
    $('#hospital_job_total_compensation').attr('readonly', true);
    $('#hospital_job_fee_per_chart').attr('readonly', false);
    $("#hospital_job_end_date").attr("readonly", true);
    $('#rb_hourly').prop('checked', false);

}

window.job_compensation_fixed = function job_compensation_fixed(){
    $("#dslp_hourly_div").hide();
    $('.hourly-input').hide();
    if ($('#fixedfee_div .fee_elm').children().length === 0)
        $('#fixedfee_div .fee_elm').append($('#job_compensation_elements').show());
    $('#no_of_hours_in_week_div').hide();
    $('#hospital_job_no_of_hours_in_week').val('');
    $('#hours_div').hide();
    $('#chart_div').show();
    $('#hospital_job_hour_per_week').val('');
    $('#hospital_job_per_hour').val('');
    $('#hospital_job_mode_of_pay').val('Fixed Fee');
    $('#compensation_div').insertAfter('#no_chart_div');
    $('#compensation_div .hr-ph').insertAfter('#compensation_inner_div');
    $("#hospital_job_no_of_chart").rules("add", {
        required: true,
        four_digit: true,
        messages: {
            required: "Please enter no of chart",
            four_digit: 'No of chart can contain maximum of four digit'
        }
    });
    $("#hospital_job_total_compensation").rules("add", {
        required: true,
        two_dec_with_six_digit: true,
        messages: {
            required: "Please enter total compensation",
            two_dec_with_six_digit: 'Total compensation can contain maximum of six digit with two decimal'
        }
    });
    $("#hospital_job_per_hour").rules('remove');
    $("#hospital_job_fee_per_chart").rules('remove');
    $('#hospital_job_total_compensation').attr('readonly', false);
    $('#hospital_job_fee_per_chart').attr('readonly', true);
    $("#hospital_job_end_date").attr("readonly", true);
    $('#rb_hourly').prop('checked', false);
}

window.remove_job_highlight_details = function remove_job_highlight_details(elem){
    let id = $(elem).closest('li').attr('id');
    if ($('.list_inline_ext').find('#'+id+ '.image-on-small').length === 1)
        $('.list_inline_ext').find('#'+id+ '.image-on-small').removeClass('image-on-small');
    $('.list_inline_ext').find('#'+id).next().find('input').prop("checked",false);
    $(elem).closest('li').remove();
    $('.list_inline_ext').find('#'+id).next().find('input').trigger("change");
    if ($('.job_credential_ul .image-on-small').length < 1 && $('.list_inline_ext').find('#'+id+ '.image-off-small').hasClass('job_credential_chk'))
        if( $('.certification-panel-collapse.in').length < 3) {
            $('.certification-panel-collapse').first().parent().find('.panel-heading').find('a').removeClass('collapsed');
            $('.certification-panel-collapse').first().removeAttr("style");
            $('.certification-panel-collapse').first().addClass("in");
            $('.certification-panel-collapse').first().attr("aria-expanded","true");
            $('.certification-panel-collapse').first().parent().find('.panel-heading').find('span').addClass('glyphicon-triangle-bottom');
            is_empty_type('.job_credential_chk', "Please choose atleast one credential");
        }

}

window.formatDate = function formatDate(date) {
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    return (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day + '-' + year;
}

window.hourly_diff = function hourly_diff(start_date, end_date, type){
  var weekDiff = Math.ceil((end_date - start_date + 1) / (1000 * 60 * 60 * 24)) / 7;
  if (($('#rb_part_time').is(':checked') === true || $('#rb_full_time').is(':checked') === true) && type == 'compensation')
    weekDiff =  Math.ceil(calcBusinessDays(start_date, end_date, true))/5;
  return weekDiff;
}

window.days_diff = function days_diff(start_date, end_date){
    var dayDiff = Math.ceil((end_date - start_date + 1));
    return dayDiff;
}

window.get_new_date = function get_new_date(date){
    var new_date = date.split("-");
    return new Date(new_date[2], new_date[0] - 1, new_date[1]);
}

window.DateFromString = function DateFromString(str, no_of_days){
    str = str.split(/\D+/);
    str = new Date(str[2],str[0]-1,(parseInt(str[1])+no_of_days));
    return MMDDYYYY(str);
}

window.MMDDYYYY = function MMDDYYYY(str) {
    var ndateArr = str.toString().split(' ');
    var Months = 'Jan Feb Mar Apr May Jun Jul Aug Sep Oct Nov Dec';
    return (parseInt(Months.indexOf(ndateArr[1])/4)+1)+'-'+ndateArr[2]+'-'+ndateArr[3];
}

window.AddDays = function AddDays(date, no_of_days) {
    var date = date;
    var ndate = DateFromString(date, no_of_days);
    var new_date = ndate.split('-')
    if (new_date[0].length == 1)
        new_date[0] = "0" + new_date[0];
    ndate = new_date.join("-")
    return ndate;
}


$(function () {
    $('[data-toggle="tooltip"]').tooltip();
})

$(function () {
    $('.job-comp-tltp').tooltip({
        container: '.job-comp-tltp'
    });
    $('.job-hourly-tltp').tooltip({
        container: '.job-hourly-tltp'
    });
    $('.job-chart-tltp').tooltip({
        container: '.job-hourly-tltp'
    });
    $('.job-fixed-tltp').tooltip({
        container: '.job-hourly-tltp'
    });
})

window.is_empty_type = function is_empty_type(class_type, content) {
    //cls_type = class_type == '.job_credential_chk' ? $(class_type).closest('.in').length == 0 ? $(class_type).closest('.collapse').find('.image-off-small') : $(class_type).closest('.in').find('.image-off-small') :  $(class_type)
    let cls_type =  $(class_type);
    cls_type.first().tooltipster({
        trigger: 'track',
        onlyOne: false,
        position: 'top',
        animation: 'grow',
        updateAnimation: false,
        delay: 800,
        offsetX: 100,
        minWidth: 150,
        maxWidth: 250
    });
    cls_type.first().tooltipster('update', content);
    cls_type.first().tooltipster('show');
}


window.calcBusinessDays = function calcBusinessDays(startDate, endDate, getWorkingDays) { // input given as Date objects
    startDate = new Date(startDate);
    endDate = new Date(endDate);

    // Validate input
    if (endDate < startDate)
        return 0;

    // Calculate days between dates
    var millisecondsPerDay = 86400 * 1000; // Day in milliseconds
    startDate.setHours(0,0,0,1);  // Start just after midnight
    endDate.setHours(23,59,59,999);  // End just before midnight
    var diff = endDate - startDate;  // Milliseconds between datetime objects
    var days = Math.ceil(diff / millisecondsPerDay);

    if(getWorkingDays){
        // Subtract two weekend days for every week in between
        var weeks = Math.floor(days / 7);
        days = days - (weeks * 2);

        // Handle special cases
        var startDay = startDate.getDay();
        var endDay = endDate.getDay();

        // Remove weekend not previously removed.
        if (startDay - endDay > 1)
            days = days - 2;

        // Remove start day if span starts on Sunday but ends before Saturday
        if (startDay == 0 && endDay != 6)
            days = days - 1;

        // Remove end day if span ends on Saturday but starts after Sunday
        if (endDay == 6 && startDay != 0)
            days = days - 1;
    }
    return days;
}

window.showCustomConfirmDialog = function showCustomConfirmDialog(link){
    const message = link.attr('data-confirm')
    const html = `
        <div class="modal" id="confirmationDialog" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog" style="width: 300px;">
                <div class="modal-content">
                    <div class="modal-header">
                        <a class="close exclude-confirmation-dialog custom-confirm-cancel" data-dismiss="modal">×</a>
                        <h3 class="modal-title" style="font-size: 20px">Confirm Message</h3>
                    </div>
                    <div class="modal-body">
                        <p style="font-size: 16px;text-align: left">${message}</p>
                    </div>
                    <div class="modal-footer">
                        <a data-dismiss="modal" style="line-height: 35px; color: white" class="btn btm-sm-pri mrg-lft-2 custom-confirm-cancel">Cancel</a>
                        <a data-dismiss="modal" style="line-height: 35px; color: white" class="btn btm-sm-pri-green mrg-lft-2 btn-primary confirm">OK</a>
                    </div>
                </div>
            </div>
        </div>
    `;

    const modal = $(html).modal();
    modal.on('hidden.bs.modal', function () {
        modal.remove(); // Remove the modal from the DOM after it's closed
    });
}

window.deleteJobTemplate = function deleteJobTemplate(sender){
    var selectTemplate = $("#select_template")
    showCustomConfirmDialog(sender)
    $('#confirmationDialog .confirm').click(function () {
        var params = "id=" + selectTemplate.val();

        $(this).closest('.element').remove();
        $.ajax({
            method: "post",
            url: "/hospital_job_posting/delete_job_template",
            data: params,
            success: function (response) {
            window.location = '/hospital_job_posting/new';
            }
        });
    });
}


window.showSplitJobOptionIfNecessary = function showSplitJobOptionIfNecessary(){
  var start_new_date =  get_new_date($("#hospital_job_start_date").val());
  var end_new_date = get_new_date($("#hospital_job_end_date").val());

  var daysDiff = Math.ceil((end_new_date - start_new_date + 1) / (1000 * 60 * 60 * 24));
  var number_of_charts = parseFloat($("#hospital_job_no_of_chart").val());

  if (number_of_charts > (20 * daysDiff)){
    $("#divMultijobPerChart").show();
  }else{
    $("#divMultijobPerChart").hide();
  }
}
