$(document).on('turbolinks:load', function() {
//Adding Regular Expression For Validation
    $.validator.addMethod("zipcodeRegex", function (value, element) {
        return this.optional(element) || /^\d{5}(?:-\d{4})?$/.test(value);
    }, "zipcode should be 5 digit.");

    $.validator.addMethod("emailRegex", function (value, element) {
        return this.optional(element) || /^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i.test(value);
    }, "Please provide a valid email.");

    $.validator.addMethod("passwordRegex", function (value, element) {
        return this.optional(element) || /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*$/.test(value);
    }, "Password should have a minimum of 8 characters, 1 lowercase, 1 uppercase & 1 number");

    $.validator.addMethod("phoneRegex", function (value, element) {
        return this.optional(element) || /^\d{3}-\d{3}-\d{4}$/.test(value);
    }, "Phone number format is 000-000-0000");

    $.validator.addMethod("experienceRegex", function (value, element) {
        return this.optional(element) || /^[0-9]{1,2}$/.test(value);
    }, "Please provide a number between 0 and 99");

    $.validator.addMethod("yearRegex", function (value, element) {
        return this.optional(element) || /^[0-9]{1,2}$/.test(value);
    }, "Please provide valid year");

    $.validator.addMethod("monthRegex", function (value, element) {
        return this.optional(element) || /^[0-11]{1,2}$/.test(value);
    }, "Please provide valid month");

    $.validator.addMethod("two_digit_one_dec_Regex", function (value, element) {
        return this.optional(element) || /^[1-9]\d?(?:\.\d{1})?$/.test(value);
    }, "Please provide an input with two digits and one decimal");

    $.validator.addMethod("one_digit_two_dec_Regex", function (value, element) {
        return this.optional(element) || /^\d?(?:\.\d{1,2})?$/.test(value);
    }, "Please provide an input with one digits and two decimal");

    $.validator.addMethod("two_digit_two_dec_Regex", function (value, element) {
        return this.optional(element) || /^[1-99]\d?(?:\.\d{1,2})?$/.test(value);
    }, "Please provide an input upto two digits and two decimal");

    $.validator.addMethod("three_digit_two_dec_Regex", function (value, element) {
        return this.optional(element) || /^((999((\.|,)[0-9]{1,3})?)|([0-9]{1,3}((\.|,)[0-9]{0,2})?))$/.test(value);
    }, "Please provide an input with three digits and two decimal");

    $.validator.addMethod("numberRegex", function (value, element) {
        return this.optional(element) || /^[0-9]*$/.test(value);
    }, "The value entered is not an number");

    $.validator.addMethod("upto100Regex", function (value, element) {
        return this.optional(element) || /^[1-9][0-9]?$|^100$/.test(value);
    }, "Please provide an input upto 100");

    $.validator.addMethod("websiteRegex", function (value, element) {
        return this.optional(element) || /^(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/.test(value);
    }, "Please provide valid website url");

    $.validator.addMethod("three_digit", function (value, element) {
        return this.optional(element) || /^\d{1,3}?$/.test(value);
    }, "Please provide valid three digit number");

    $.validator.addMethod("four_digit", function (value, element) {
      return this.optional(element) || /^\d{1,4}?$/.test(value);
    }, "Please provide valid four digit number");

  $.validator.addMethod("ahima_seven_digit", function (value, element) {
      return this.optional(element) || /^\d{7}?$/.test(value);
    }, "AHIMA # is seven dights");

    $.validator.addMethod("aapc_eight_digit", function (value, element) {
      return this.optional(element) || /^\d{8}?$/.test(value);
    }, "AAPC # is eight digits");

    $.validator.addMethod("two_dec_with_three_digit", function (value, element) {
        return this.optional(element) || /^\d{1,3}(?:\.\d{1,2})?$/.test(value);
    }, "Please provide an input upto three digits and two decimal");

    $.validator.addMethod("two_dec_with_six_digit", function (value, element) {
        return this.optional(element) || /^\d{1,6}(?:\.\d{1,2})?$/.test(value);
    }, "Please provide valid input");

    $.validator.addMethod("password_not_same", function(value, element) {
        return $('#user_current_password').val() != $('#user_new_password').val()
    }, "New password should not match old password");

    $.validator.addMethod("hospital_password_not_same", function(value, element) {
        return $('#hospital_setting_current_password').val() != $('#hospital_setting_new_password').val()
    }, "New password should not match old password");

    $.validator.addMethod("hospital_password_not_same", function(value, element) {
        return $('#hospital_setting_current_password').val() != $('#hospital_setting_new_password').val()
    }, "New password should not match old password");

    $.validator.addMethod("allow_two_digit", function(value, element) {
        return this.optional(element) || /^\d{1,2}?$/.test(value); 
    }, "Please enter upto two digits only, no cents.");

    $.validator.addMethod("facebookUserName", function(value, element) {
        return this.optional(element) || /^[A-Za-z0-9.]{5,50}$/.test(value);
    }, 'Invalid. Please check Facebook username');
});
