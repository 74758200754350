window.setStatus = function setStatus(index, newStatus, urlHash) {
    var method = urlHash.method;
    var id = urlHash.id
    var params = urlHash.params;
    $.ajax({
        method: 'put',
        url: '/' + method + '/' + id,
        data: params,
        success: function (response) {
            afterStatusChange(index, newStatus);
        }
    });
}

window.afterStatusChange = function afterStatusChange(index, newStatus, id) {
    var status = camelizeString(newStatus);    
    if($("#status_" + index) && $("#status_" + index)[0]) {
        $("#status_" + index).html(status);
        $("#status_" + index).attr('class', 'alert alert-info');
        $("#status_" + index).attr('style', 'font-size:14px');
    }
    var element = "#job_alert_" + id + '_'+ index;
    if($(element) && $(element)[0]) {
        $(element).hide();
    }
    if($("#alert_message") && $("#alert_message")[0]) {
        $("#alert_message").attr('class', 'alert alert-info alrt-font');
        message = ''
        if(newStatus == 'SIGN_PROCESS') {
            var message = '. Please check your email for the documents to e-sign.'
        }
        $("#alert_message").html('You have successfully set the status as ' + status + message) ;
    }
    if($("#action_" + index) && $("#action_" + index)[0]) {
        $("#action_" + index).hide();
    }
    // commented for MVP
    //    if(newStatus == 'APPROVED_TO_PAY' && $("#generate_invoice_id_" + index) && $("#generate_invoice_id_" + index)[0]) {
    //        $("#generate_invoice_id_" + index).show();
    //    }
    if(newStatus == 'INVOICED' && $("#generate_invoice_id_" + index) && $("#generate_invoice_id_" + index)[0]) {
        $("#generate_invoice_id_" + index).hide();
    }
    if((newStatus == 'DELETED' || newStatus == 'ARCHIVED') && $("#archive_and_deleted_id_" + index)) {
        $("#archive_and_deleted_id_" + index).hide();
    }

    if((newStatus == 'WITHDRAWN' || newStatus == 'SIGN_PROCESS') && $("#accept_and_decline_id_" + index)) {
      $("#accept_and_decline_id_" + index).hide();
    }

    if($("#action_span_" + index) && $("#action_span_" + index)[0]) {
        $("#action_span_" + index).html('OFFERED');
    }
    if($("#action_button_" + index) && $("#action_button_" + index)[0]) {
        $("#action_button_" + index).attr('class', 'alert alert-info');
        $("#action_button_" + index).attr('disabled', 'disabled');
    }
    setTimeout(function(){
        hideAlert();
    }, 10000);

    if (newStatus == "CONTRACTED") {
        let current_location = window.location.href;
        if(current_location.indexOf("coder_dashboard") !== -1) {
            window.location.href = current_location + "?tab=inqueue";
        } else {
            window.location.reload()
        }
        
    }
}

window.openPage = function openPage(pageUrl){
  window.open(pageUrl, '_blank');
}

window.selectCoder = function selectCoder(index, hospitalJobId, userHospitalJobId, newStatus, userId) {
    var params = "status=" + newStatus + "&hospital_job_id=" + hospitalJobId;

    if (userId != null){
      params += '&user_id=' + userId;
    }

    $.ajax({
        method: "put",
        url: "/user_hospital_jobs/" + userHospitalJobId,
        data: params,
        success: function (response) {
            response = response.trim().split("'")[1];
            if(response == 'ANOTHER_SELECTED') {
                alert('Another coder was already offered.')
                return;
            }

            window.location.reload();
        }
    });
}

window.removeFromTopMatches = function removeFromTopMatches(hospitalJobId, userId){
  var params =  "hospital_job_id=" + hospitalJobId + '&user_id=' + userId;

  $.ajax({
    method: "post",
    url: "/hospital_job_posting/" + hospitalJobId + "/remove_top_coder",
    data: params,
    success: function (response) {
      window.location.reload();
    }
  });
}

window.removeOtherApplicants = function removeOtherApplicants(index) {
    for(var i = 0; i < 10; i++) {
        if(i != parseInt(index)) {
            if($("#action_span_" + i) && $("#action_span_" + i)[0]) {
                $("#action_span_" + i).html('WITHDRAWN');
            }
            if($("#action_button_" + i) && $("#action_button_" + i)[0]) {
                $("#action_button_" + i).attr('class', 'alert alert-info');
                $("#action_button_" + i).attr('disabled', 'disabled');
            }
            if($("#remove_action_button_" + i)) {
                $("#remove_action_button_" + i).hide();
            }
        }
    }
}

window.submitJob = function submitJob(index, userHospitalJobId, newStatus) {
    var params = "status=" + newStatus;
    var str = $( "form" ).serialize();
    var urlHash = {}
    urlHash.params = params + '&' + str;
    urlHash.method = 'user_hospital_jobs';
    urlHash.id = userHospitalJobId;
    setStatus(index, newStatus, urlHash);
}

window.onCompleteJob = function onCompleteJob() {
    var result = true;
    if($('.image-on-small').length > 0) {
        result = false;
        if($("#validation_alert_message") && $("#validation_alert_message")[0]) {
            $("#validation_alert_message").attr('class', 'alert alert-info red-color');
            $("#validation_alert_message").html('You have chosen incomplete reasons to complete a job. Please recheck.') ;
        }
    }
    setTimeout(function(){
        hideAlert();
    }, 5000);
    return result;
}

window.onCompleteOrIncompleteJob = function onCompleteOrIncompleteJob(index, userHospitalJobId, completeStatus, incompleteStatus) {
    $("#complete_button_id").click(function() {
        if(onCompleteJob()) {
            return submitJob(index, userHospitalJobId, completeStatus);
        }
        else
            return false;
    });
    $("#incomplete_button_id").click(function() {
        return submitJob(index, userHospitalJobId, incompleteStatus);
    });
}

window.onSubmitJob = function onSubmitJob(index, userHospitalJobId, newStatus) {
    $("#submit_button_id").click(function() {
        var result = validateCoderIncompleteReasons();
        if(result) {
            return submitJob(index, userHospitalJobId, newStatus);
        }
        else
            return false;
    });
}

window.camelizeString = function camelizeString(value) {
    var camelizedWords = value;
    if(value != '') {
        var array = value.split('_');
        var string, subString;
        var camelizedWordArray = [];
        for(var i = 0; i < array.length; i++) {
            string = array[i][0].toUpperCase();
            subString = array[i].substr(1).toLowerCase();
            var camelizedString = string.concat(subString);
            camelizedWordArray.push(camelizedString);
        }
        camelizedWords = camelizedWordArray.join(' ');
    }
    return camelizedWords;
}

window.showCharactersLeft = function showCharactersLeft(id, maxLength, charLeftText) {
    $('#' + id).keyup(function () {
        if( $(this).val().trim() == '')
            $(this).val($(this).val().trim());
        var left = parseInt(maxLength) - $(this).val().length;
        if (left < 0) {
            left = 0;
        }
        $('#' + charLeftText).text(left + ' characters left');
    });
}

window.hideAlert = function hideAlert() {
    if($("#modal_alert_message") && $("#modal_alert_message")[0]) {
        $("#modal_alert_message").attr('class', '');
        $("#modal_alert_message").html('');
    }
    if($("#alert_message") && $("#alert_message")[0]) {
        $("#alert_message").attr('class', '');
        $("#alert_message").html('');
    }
    if($("#validation_alert_message") && $("#validation_alert_message")[0]) {
        $("#validation_alert_message").attr('class', '');
        $("#validation_alert_message").html('');
    }    
}

window.showIncompleteReasonExplanation = function showIncompleteReasonExplanation(id, count) {
    $('#explain_reason_' + count).attr('checked', true);
    $('#explain_reason_' + count).toggle();
    $('#explain_reason_' + count + '_result').toggle();
    $('#reason_' + count + '_char_left').toggle();
    showTextArea(id, 'explain_reason_' + count + '_result');
}

window.validateCoderIncompleteReasons = function validateCoderIncompleteReasons() {
    var message = validateCommentForOtherOption();
    if(message == '') {
        message = validateAllExplanationOfReason();
    }
    var toContinue = (message == '')
    if(!toContinue) {
        if($("#validation_alert_message") && $("#validation_alert_message")[0]) {
            $("#validation_alert_message").attr('class', 'alert alert-info red-color');
            $("#validation_alert_message").html(message);
        }
        setTimeout(function(){
            hideAlert();
        }, 5000);
    }
    return toContinue;
}

window.validateCommentForOtherOption = function validateCommentForOtherOption() {
    var message = '';
    if($('#explain_reason_1_7') && $('#explain_reason_1_7')[0]) {
        if($('#explain_reason_1_7').val() == 'Other –In comments' && $('#explain_reason_1_7').is(':checked')) {
            if($('#comment').val() == '') {
                message = 'You have chosen to enter other reason in comment, so please enter comment to continue.';
            }
        }
    }
    return message;
}

window.validateAllExplanationOfReason = function validateAllExplanationOfReason() {
    var message = '';
    var result = validateExplanationOfReason('2');
    if(result) {
        result = validateExplanationOfReason('5');
        if(!result) {
            message = 'Please give more info about the inaccessible systems.'
        }
    }
    else {
        message = 'Please give more info about the missing charts.'
    }
    return message;
}

window.validateExplanationOfReason = function validateExplanationOfReason(count) {
    var result = true;
    if($('#image_' + count) && $('#image_' + count)[0]) {
        if($('#image_' + count).hasClass('image-on-small')) {
            if($('#explain_reason_' + count + '_result').val() == '') {
                result = false;
            }
        }
    }
    return result;
}
