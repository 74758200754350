$(document).on('turbolinks:load', function() {
  var ids = ["hospital_preferences_form", "hospital_profile_infos",  "hospital_provider_info_form", 
  "hospital_speciality_info_form", "credential_educational_info_form", "general_info_form", 
  "professional_info_form", "speciality_info_form", "work_preference_form"]
  // Loop through the IDs and run the function if the elements exist
  for (var i = 0; i < ids.length; i++) {
    var element = $("#" + ids[i]);
    if (element.length) {
      confirmationExit(); // Run the 'confirmationExit' method on the element
    }
  }
});

function confirmationExit() {
  var formChanged = false;
  $("form").on("change", "input:not('.exclude-confirmation-dialog'), textarea, select", function() {
    formChanged = true;
  });

  $(document).on("click", "a:not('.exclude-confirmation-dialog')", function(event) {
    let targetAnchor = this;
    $(targetAnchor).off("click");
    const isRemote = $(this).is('[data-remote]');
    const isNoPrompt = $(this).is('[data-behavior="no-prompt"]');
    let allowNavigate = handleWorkPreferenceForm();

    if (!isRemote && !isNoPrompt && !isExcludedElements(this) && allowNavigate) {
      if (formChanged) {
        var el = $(this);
        $(this).attr('data-confirm', "Are you sure you want to leave? You have unsaved changes.")
        showCustomConfirmDialog($(this));
        $('#confirmationDialog .custom-confirm-cancel').click(function () {
          el.removeAttr('data-confirm');
          event.preventDefault();
        });
        $('#confirmationDialog .confirm').click(function () {
          formChanged = false;
          el.removeAttr('data-confirm')
          el[0].click();
        })
        $(targetAnchor).on("click");
      }
    } else {
      $(targetAnchor).on("click");
      event.preventDefault();
    }
  });
}

function handleWorkPreferenceForm(){
  const isWorkPreferenceForm = $("#work_preference_form").length == 1;
  let allowNavigate = false;

  if (isWorkPreferenceForm) {
    if ($("#hourly-rate").length == 1) {
      if($("#hourly-rate").val() != ""){
        $("#hourly-rate").tooltipster(
          "update",
          "Please click on Confirm and save your Hourly Rate."
        );
      } else {
        $("#hourly-rate").tooltipster(
          "update",
          "Please enter Hourly Rate. and click on Confirm."
        );
      }
    
      $("#hourly-rate").tooltipster("show");
      $("#hourly-rate").focus();
    } else {
      allowNavigate = true;
    }
  } else {
    allowNavigate = true;
  }
  return allowNavigate;
}

function showCustomConfirmDialog(link) {
  const message = link.attr('data-confirm')
  const html = `
      <div class="modal" id="confirmationDialog" style="z-index: 99999999" data-backdrop="static" data-keyboard="false">
          <div class="modal-dialog" style="width: 300px;">
              <div class="modal-content">
                  <div class="modal-header">
                      <a class="close exclude-confirmation-dialog custom-confirm-cancel" data-dismiss="modal">×</a>
                      <h3 class="modal-title" style="font-size: 20px">Confirm Message</h3>
                  </div>
                  <div class="modal-body">
                      <p style="font-size: 16px;text-align: left">${message}</p>
                  </div>
                  <div class="modal-footer">
                      <a data-dismiss="modal" style="line-height: 35px; color: white" class="btn btm-sm-pri mrg-lft-2 custom-confirm-cancel">Cancel</a>
                      <a data-dismiss="modal" style="line-height: 35px; color: white" class="btn btm-sm-pri-green mrg-lft-2 btn-primary confirm">OK</a>
                  </div>
              </div>
          </div>
      </div>
  `;

  const modal = $(html).modal();
  modal.on('hidden.bs.modal', function () {
      modal.remove(); // Remove the modal from the DOM after it's closed
  });
}

function isExcludedElements(ele) {
  const  element = $(ele);
  const classes = ['remove_fields', 'add_fields', 'no_confirm_exit', 'likdn', 'custom-confirm-cancel', 'confirm'];

  for (var i = 0; i < classes.length; i++) {
    if (element.hasClass(classes[i])) {
      return true;
    }
  }
}
