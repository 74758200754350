$(document).on('turbolinks:load', function() {
  if ($("#sign_up_form").length > 0) {
    $("#registration_modal").on("shown.bs.modal", function () {
      if (!$('select.user_patient_type').data('select2')) {
        $("select.user_patient_type").select2({
          width: "100%",
          closeOnSelect: false,
        });
      }

      window.addEventListener("beforeunload", function () {
        $("#sign_up_form")[0].reset();
        $(".user_patient_type").select2("val", "");
        window.removeEventListener("beforeunload");
      });
    });

    $("#hospital_registration_modal").on("shown.bs.modal", function () {
      window.addEventListener("beforeunload", function () {
        $("#hospital_sign_up_form")[0].reset();
        window.removeEventListener("beforeunload");
      });
    });

    $("#company_registration_modal").on("shown.bs.modal", function () {
      window.addEventListener("beforeunload", function () {
        $("#company_sign_up_form")[0].reset();
        window.removeEventListener("beforeunload");
      });
    });

    var formSubmitted = false;
    $("#sign_up_form").on("submit", function (e) {
      if (formSubmitted) {
        return;
      }

      e.preventDefault();
      if ($("#sign_up_form").valid()) {
        const path = $("#validate_user_details").data("path");

        $.ajax({
          method: "GET",
          url: path,
          dataType: "JSON",
          data: {
            email: $("#input_email").val(),
            phone: $("#user_phone").val()
          },
          success: function (result) {
            const duplicateEmail = result["email"] == true;
            const duplicatePhone = result["phone"] == true;
            const $emailField = $("#input_email");
            const $phoneField = $("#user_phone");

            if (duplicateEmail) {
              $emailField.tooltipster(
                "update",
                "Your email account is a registered user with RCMx."
              );
              $emailField.tooltipster("show");
            }

            if (duplicatePhone) {
              $phoneField.tooltipster(
                "update",
                "Your phone number is a registered user with RCMx."
              );
              $phoneField.tooltipster("show");
            }

            if (duplicateEmail && !duplicatePhone) {
              $emailField.trigger("focus");
            } else if (!duplicateEmail && duplicatePhone) {
              $phoneField.trigger("focus");
            }

            if (!duplicateEmail && !duplicatePhone) {
              formSubmitted = true;
              $("#sign_up_form").submit();
            }
          },
        });
      }
    });
  }
});
