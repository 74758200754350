$(document).on("turbolinks:load", function () {
  $('[data-toggle="popover"]').popover();
  if (decodeURIComponent(window.location.search.substring(1)) === "profile_return=true?edit_coding_rate=true" ) {
    loadChartTypeModal();
  }

  function showHourlyRateModal() {
    if ($("#work_preference_form").validate().element("#hourly-rate") && $("#hourly-rate").val() !== "") {
      setTimeout(function(){
        if (document.activeElement.tagName != "A" && document.activeElement.tagName != "BUTTON") {
          $("#hourly-rate-confirm").click();
        }
      }, 200);
    }
  }

  function byChartFieldEmpty() {
    let blankValue = false;
    $(".by_chart:not([readonly])").each(function () {
      if ($(this).val() == "") {
        blankValue = true;
      }
    });
    return blankValue;
  }

  function SaveRates() {
    $("#chart_type_modal").modal("hide");
    let valuesToSubmit = $("#chart_type_form :input").serialize();
    const path = $("#btnSave").data("path");
    let profileReturn = $("#profile_return").val();
    $.ajax({
      type: "PUT",
      url: `${path}?profile_return=${profileReturn}`, //submits it to the given url of the form
      data: valuesToSubmit,
      dataType: "text",
    }).then(function (json) {
      let currentUrl = window.location.href;
      window.location.href = currentUrl.split("?")[0];
    });
  }

  function validateFormRules() {
    let rules = new Object();
    let patientTypes = $("#patientTypes").data("name");

    $.each(patientTypes, function (index, patient_type_name) {
      rules[`user_patient_type[${patient_type_name}][number_of_years]`] = {
        range: [1, 50],
        required: function (element) {
          return $(element).closest("tr").find(":checkbox").is(":checked");
        },
      };
      rules[`user_patient_type[${patient_type_name}][by_hour]`] = {
        min: 1,
        two_digit_two_dec_Regex: true,
        required: function (element) {
          return $(element).closest("tr").find(":checkbox").is(":checked");
        },
      };
      rules[`user_patient_type[${patient_type_name}][by_chart]`] = {
        min: 1,
        two_digit_two_dec_Regex: true
      };
    });

    rules["hourly-rate"] = {
      allow_two_digit: true,
      required: function() {
        return $("#hourly-rate").length == 1
      }
    };

    rules["user[facebook_username]"] = {
      facebookUserName: true
    };

    return rules;
  }

  function loadChartTypeModal(){
    const $target = $("#userPatientTypeRate");
    const path = $target.data("path");

    $.ajax({
      method: "GET",
      url: path,
      success: function (result) {
        if($("#chart_type_modal").is(":visible")){
          return;
        } else {
          $target.html(result);
          onChartTypeModalLoad();
          if($("#hourly-rate").length > 0){
            $(".by_hour_traves:not([readonly])").val(
              parseFloat($("#hourly-rate").val()).toFixed(2)
            );
          }
          $("#chart_type_modal").modal("show");
        }
      },
    });
  }

  function onChartTypeModalLoad() {
    $("#chart_type_modal").find('[data-toggle="tooltip"]').tooltip();
    $("#chart_type_modal").find('[data-toggle="popover"]').popover();
    if($("#hourly-rate").length > 0) {
      $("#hourly-rate").tooltipster("hide");
    }
    
    if (!$("#work_preference_form").validate().element("#user_facebook_username")) {
      $("#user_facebook_username").val("");
      $("#user_facebook_username").tooltipster("hide");
    }

    $("#chart_type_modal input").tooltipster({
      trigger: "track",
      onlyOne: false,
      position: "top",
      animation: "grow",
      updateAnimation: false
    });

    $(".by_houry").on("keydown", function (e) {
      if ($.inArray(e.key, ['Backspace', 'Delete', 'Tab', 'Escape', 'Enter', '.', 'Decimal']) !== -1 ||
        // Allow: Ctrl+A
        (e.key == 'a' && e.ctrlKey === true) ||
        // Allow: home, end, left, right
        (e.key == 'Home' || e.key == 'End' || e.key == 'ArrowLeft' || e.key == 'ArrowRight')) {
        // Let it happen, don't do anything
        return;
      }
      // Ensure that it is a number and stop the keypress
      if ((e.shiftKey || (e.key < '0' || e.key > '9')) && (e.key < 'NumPad0' || e.key > 'NumPad9')) {
        e.preventDefault();
      }
    });

    $("#chart_type_modal").on("hidden.bs.modal", function () {
      $("#chart_type_modal .tooltipstered").tooltipster("hide");
    });

    $(".chart_type_parent_rows, .chart_type_child_rows").each(function () {
      if ($(this).children().first().find(".image-on-small").length == 1) {
        $(this).children().find("input").prop("readonly", false);
        $(this).children().find(".notify_div").css("pointer-events", "all");
      } else {
        $(this).children().find("input").prop("readonly", true);
        $(this).children().find(".notify_div").css("pointer-events", "none");
      }
    });

    $(".chart-type-modal").on("keydown", "input, textarea", function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
        const textAreas = $(".chart-type-modal").find(
          ".txt-new-yrs, .jobs-txt, .btn"
        );
        const currentIndex = textAreas.index(this);
        const nextIndex = currentIndex + 1;
        if (nextIndex < textAreas.length) {
          textAreas[nextIndex].focus();
        }
      }
    });

    $("#chart_type_form .by_houry").on("change", function (event) {
      let val = $(this).val();
      if (val !== "" && val.length <= 6) {
        let num = parseFloat(val);
        $(this).val(num.toFixed(2));
      }
      if ($(this).prop("readonly")) $(this).tooltipster("hide");
    });

    $("#chart_type_form .by_houry").on("keyup", function (event) {
      if ($(this).attr("id").indexOf("chart") >= 0 && $(this).val() !== "")
        $(this).parent().prev().find("input").tooltipster("hide");
    });

    $("#chart_type_form .txt-new-yrs").on("change", function (event) {
      if ($(this).prop("readonly")) $(this).tooltipster("hide");
    });

    $.each($("[id^='specialpatient_type_']"), function(index, obj){
      if($(this).find(".pat_type").is(':checked')) {
        let id = $(this).find(".pat_type").val();
        if($(`#speciality_${id}`).find(".pat_type").length > 0) {
          let anyChildChecked = false;

          $.each($(`#speciality_${id}`).find("tr").find(".pat_type"), function(){
            if($(this).is(':checked')) {
              anyChildChecked = true;
            }
          });

          if(!anyChildChecked) {
            $(`#speciality_${id}`).find('.samb').first().trigger('click');
          }
        }
      }
    });

    $.each($(".notify_div"), function(){
      $(this).on("click", function(){
        let mainDiv = $(this);
        if(mainDiv.hasClass("image-on-small")) {
          mainDiv.removeClass("image-on-small");
          mainDiv.find('.notify_me').removeAttr('checked')
        } else {
          mainDiv.addClass("image-on-small");
          mainDiv.find('.notify_me').attr('checked', true)
        }
      });
    });

    $("#btnSave").on("click", function (e) {
      if ($("#work_preference_form").valid()) {
        if (byChartFieldEmpty()) {
          showCustomConfirmDialog($(this));
          $(".custom-confirm-cancel").html("No");
          $("#confirmationDialog .confirm").html("Yes");
          $("#confirmationDialog .confirm").on("click", SaveRates);
        } else {
          SaveRates();
        }
      } else {
        e.preventDefault();
      }
    });
  }

  $("#work_preference_form input").tooltipster({
    trigger: "track",
    onlyOne: false,
    position: "top",
    animation: "grow",
    updateAnimation: false,
    minWidth: 150,
    maxWidth: 200,
    delay: 800,
  });

  $("#work_preference_form").on("keydown", function(event){
    if(event.key == 'Enter') {
      event.preventDefault();
      return false;
    }
  });

  $("#hourly-rate-confirm").on("click", function () {
    loadChartTypeModal();
  });

  $("#hourly-rate").on("keyup", function (e) {
    if (e.key === "Enter") {
      showHourlyRateModal();
    }

    if ($("#work_preference_form").validate().element("#hourly-rate") && $(this).val() !== "") {
      $("#hourly-rate-confirm").show();
    } else {
      $("#hourly-rate-confirm").hide();
    }
  });

  $("#hourly-rate").on("focusout", function () {
    setTimeout(function(){
      if (document.activeElement.tagName != "A" && document.activeElement.tagName != "BUTTON") {
        showHourlyRateModal();
      }
    }, 200);
  });

  if($("#work_preference_form").length > 0) {
    $("#work_preference_form").validate({
      rules: validateFormRules(),
      errorPlacement: function (error, element) {
        $(element).tooltipster("option", "theme", "my-custom-theme");

        if ($(element).attr("id").indexOf("number_of_years") >= 0) {
          $(element).tooltipster("option", "position", "left");
          $(element).tooltipster("update", $(error).text());
          $(element).tooltipster("show");
        }

        if (
          $(element).attr("id").indexOf("by_hour") >= 0 &&
          ($(element).val() === "" || $(element).val() == "0.00")
        ) {
          $(element).tooltipster("update", "Please enter by hour rate");
          $(element).tooltipster("show");
        } else {
          $(element).tooltipster("option", "offsetX", 0);
          $(element).tooltipster("update", $(error).text());
          $(element).tooltipster("show");
        }
      },
      success: function (label, element) {
        $(element).tooltipster("hide");
      },
      onfocusout: function(element) {
        if($(element).valid() && $(element).hasClass("tooltipstered")){
          $(element).tooltipster("hide");
        }
      },
      onkeyup: false
    });

    var formSubmitted = false;
    $("#work_preference_form").on("submit", function (e) {
      if (formSubmitted) {
        return;
      }
      e.preventDefault();
      if ($("#hourly-rate").length == 1 && $("#hourly-rate").val() != "") {
        $("#hourly-rate").tooltipster(
          "update",
          "Please click on Confirm and save your Hourly Rate."
        );
        $("#hourly-rate").tooltipster("show");
        $("#hourly-rate").focus();   
      } else if ($("#work_preference_form").valid()) {
        formSubmitted = true;
        $("#work_preference_form").submit();
        $("[value='Save/View Profile']").attr("disabled", "disabled");
      }
    });
  }
});

window.makeAllValueAddOrRemove = function makeAllValueAddOrRemove(itemId){
  const $markedItem = $(`#${itemId}`).parents().closest("tr");
  let $targetCheckbox = $markedItem.find(".pat_type");

  if ($($targetCheckbox).is(':checked')) {
    $($targetCheckbox).removeAttr('checked');
    $(`#${itemId}`).removeClass("image-on-small");
  } else {
    $($targetCheckbox).attr('checked', true);
    $(`#${itemId}`).addClass("image-on-small");
  }

  if ($($targetCheckbox).is(':checked')){
    $markedItem.find('.txt-new-yrs').val($('#year').val());
    $markedItem.find('.notify_me').prop('checked', true);
    $markedItem.find('.notify_div').addClass('image-on-small');
    $markedItem.find('.by_hour_traves').removeClass('has_value');
    $markedItem.find("input[type=text]").prop("readonly",false);
    $markedItem.find('.notify_div').css("pointer-events", 'all');
  } else{
    $markedItem.find('.txt-new-yrs').tooltipster('hide');
    $markedItem.find("input[type=text]").val('');
    $markedItem.find("input[type=text]").prop("readonly", true);
    $markedItem.find('.notify_me').prop('checked', false)
    $markedItem.find('.notify_div').removeClass('image-on-small')
    $markedItem.find('.notify_div').css("pointer-events", 'none');
    $markedItem.find('.by_houry').tooltipster('hide');
  }
}

window.checkUncheckChild = function checkUncheckChild(chidId, itemId) {
  const $markedItem = $(`#${itemId}`);
  const $targetCheckbox = $markedItem.parent().find(".pat_type");
  const $childDiv = $(`#${chidId}`);


  if($childDiv.find("tr").length > 0) {
    $childDiv.toggle();

    if($targetCheckbox.is(':checked')){
      $($targetCheckbox).removeAttr('checked');
      $markedItem.removeClass("image-on-small");
    } else {
      $($targetCheckbox).attr('checked', true);
      $markedItem.addClass("image-on-small");
    }

    $.each($childDiv.find("tr"), function(){
      if($(this).find(".pat_type").is(':checked')){
        $(this).find("[id^='sub_patient_type_']").trigger("click");
      }
    });
  
    if($targetCheckbox.is(':checked')){
      let id = $childDiv.find("tr").first().find(".pat_type").val();
      $(`#sub_patient_type_${id}`).trigger('click');
    }
  } else {
    makeAllValueAddOrRemove(itemId)
  }
}