// Require jQuery
// global.$ = require("jquery")
// import $ from 'jquery';


console.log('Custom JxxxxxxxxxavaScript file loaded');

window.sayHello =  function sayHello() {
  console.log('Hello from cusssuu.js');
}
document.addEventListener("turbolinks:load", function() {
  document.querySelectorAll('[data-roar]').forEach(function(roaring_link) {
    roaring_link.addEventListener('click', function(event) {
      let name = roaring_link.dataset['roar']
       alert('I am ' + name + '. Hear me roar!');
       event.preventDefault();
       event.stopPropagation();
       return false;
    });
  });
});

$.myjQuery = function() {
  alert("jQuersoksoskoskoskosky");
};

window.display = function display() {
  $.myjQuery();
};
