$(document).on('turbolinks:load', function() {

    $('#company_registration_modal').on('hidden.bs.modal', function () {
        $("#company_sign_up_form .tooltipstered").tooltipster('hide');
    });

    $('#company_sign_up_form #input_zipcode').tooltipster({
        trigger: 'track',
        onlyOne: false,
        position: 'right',
        animation: 'grow',
        updateAnimation: false,
        delay: 200,
        minWidth: 150,
        maxWidth: 250

    });

    $('#company_sign_up_form #input_last_name').tooltipster({
        trigger: 'track',
        onlyOne: false,
        position: 'right',
        animation: 'grow',
        updateAnimation: false,
        delay: 200,
        minWidth: 150,
        maxWidth: 250
    });

    $('#company_sign_up_form input').tooltipster({
        trigger: 'track',
        onlyOne: false,
        position: 'left',
        animation: 'grow',
        updateAnimation: false,
        delay: 200,
        minWidth: 150,
        maxWidth: 250
    });

    $("#company_sign_up_form").validate({
        rules: {
            "company" : "required",
            "user[first_name]" : "required",
            "user[last_name]" : "required",
            "user[email]" : {
                required: true,
                emailRegex: true
            },
            "user[zipcode]" : {
                required: true,
                zipcodeRegex : "Please provide valid zip code"
            },
            "user[phone]" :{
                required: true,
                phoneRegex: true
            },
            "user[password]" : {
                required: true,
                passwordRegex : true
            },
            "password" : {
                required: true,
                passwordRegex : true
            }
        },
        messages: {
            "hospital" : "Please enter your company name",
            "user[first_name]" : "Please enter your first name",
            "user[last_name]" : "Please enter your last name",
            "user[email]" : "Please enter a valid email",
            "user[zipcode]" : {
                required : "Please enter your zip code"
            },
            "user[phone]" : {
                required : "Please enter your phone number"
            },
            "user[password]" : {
                required : "Please enter your password"
            }
        },
        errorPlacement: function (error, element) {
            $(element).tooltipster('update', $(error).text());
            $(element).tooltipster('show');
        },
        success: function (label, element) {
            $(element).tooltipster('hide');
        }

    });


});