$(document).on('turbolinks:load', function() {

    $('.prof_summary').keyup(function () {
        var value = $(this).val();
        var len = value.replace(/\r\n/g, '~~').replace(/\n/g, '~~').length;
        var left = 175 - len;
        if (left < 0) {
            left = 0;
        }
        $('.chr-lft').text(left+ ' characters left');
    });

    $('.hospital_summary').keyup(function () {
        var value = $(this).val();
        var len = value.replace(/\r\n/g, '~~').replace(/\n/g, '~~').length;
        var left = 600 - len;
        if (left < 0) {
            left = 0;
        }
        $('.chr-lft').text(left+ ' characters left');
    });

    $("#bba").click(function () {
        $("input[id='hospital_bba_doc']").click();
    });

    $("#policy").click(function () {
        $("input[id='hospital_policy_doc']").click();
    });

    if ($('#hospital_account_setting_form input:radio[name="user[user_notification_attributes][dashboard_summary]"]:checked').val() === undefined) {
        $('#dashboard_week_summary').prop('checked', true);
    }

    if ($('#hospital_account_setting_form input:radio[name="user[user_notification_attributes][industry_news]"]:checked').val() === undefined) {
        $('#industry_news_monthly').prop('checked', true);
    }


    //var cities = new Array();
    //$('#hospital_state').change(function () {
    //    window.alert("state is not matching to zipcode");
    //    var data = {'state_code': $(this).val()};
    //    $.ajax({
    //        url: "/hospital_steps/coder_state_city",
    //        type: "POST",
    //        data: JSON.stringify(data),
    //        contentType: 'application/json; charset=utf-8',
    //        dataType: 'json',
    //        success: function (data) {
    //            if (cities.length > 0)
    //                cities.splice(0,cities.length);
    //            $.each(data, function (key, val) {
    //                item = {}
    //                item ["value"] = this.valueOf();
    //                item ["data"] = this.valueOf();
    //                cities.push(item);
    //                //options.append($("<option />").val(this).text(this));
    //            });
    //        },
    //        error: function () {
    //        }
    //    });
    //});


    $('#hospital_registration_modal').on('hidden.bs.modal', function () {
        $("#hospital_sign_up_form .tooltipstered").tooltipster('hide');
    });

    if ( window.location.pathname === "/welcome.hospital" ) {
        $('.user-already-registered').modal('show');
    }

    $('#hospital_sign_up_form #input_zipcode').tooltipster({
        trigger: 'track',
        onlyOne: false,
        position: 'right',
        animation: 'grow',
        updateAnimation: false,
        delay: 200,
        minWidth: 150,
        maxWidth: 250
    });

    $('#hospital_sign_up_form #input_last_name').tooltipster({
        trigger: 'track',
        onlyOne: false,
        position: 'right',
        animation: 'grow',
        updateAnimation: false,
        delay: 200,
        minWidth: 150,
        maxWidth: 250
    });

    $('#hospital_sign_up_form input').tooltipster({
        trigger: 'track',
        onlyOne: false,
        position: 'left',
        animation: 'grow',
        updateAnimation: false,
        multiple: true,
        delay: 200,
        minWidth: 150,
        maxWidth: 250
    });
    $('#hospital_provider_info_form input#hospital_image').tooltipster({
        trigger: 'track',
        onlyOne: false,
        position: 'top',
        animation: 'grow',
        updateAnimation: false,
        delay: 800,
        offsetX: 160,
        offsetY: 130
    });

    $('#hospital_provider_info_form input,#hospital_provider_info_form textarea,#hospital_provider_info_form select').tooltipster({
        trigger: 'track',
        onlyOne: false,
        position: 'top',
        animation: 'grow',
        updateAnimation: false,
        delay: 800
    });

    $('#hospital_speciality_info_form input').tooltipster({
        trigger: 'track',
        onlyOne: false,
        position: 'top',
        animation: 'grow',
        updateAnimation: false,
        delay: 800
    });

    $('#hospital_profile_infos input#hospital_dnfb').tooltipster({
        trigger: 'track',
        onlyOne: false,
        position: 'top',
        animation: 'grow',
        updateAnimation: false,
        delay: 800
    });
    $('#hospital_profile_infos input, #hospital_profile_infos select, #hospital_profile_infos textarea').tooltipster({
        trigger: 'track',
        onlyOne: false,
        position: 'right',
        animation: 'grow',
        updateAnimation: false,
        delay: 800
    });

    $('#hospital_preferences_form input,#hospital_preferences_form textarea').tooltipster({
        trigger: 'track',
        onlyOne: false,
        position: 'top',
        animation: 'grow',
        updateAnimation: false,
        delay: 800
    });

    $('#hospital_account_setting_form input').tooltipster({
        trigger: 'track',
        onlyOne: false,
        position: 'top',
        animation: 'grow',
        updateAnimation: false,
        delay: 200,
        minWidth: 150,
        maxWidth: 250,
        offsetY: 15
    });

    $("#hospital_sign_up_form").validate({
        rules: {
            "hospital" : "required",
            "user[first_name]" : "required",
            "user[last_name]" : "required",
            "user[email]" : {
                required: true,
                emailRegex: true
            },
            "user[zipcode]" : {
                required: true,
                zipcodeRegex : "Please provide valid zip code"
            },
            "user[phone]" :{
                required: true,
                phoneRegex: true
            },
            "user[password]" : {
                required: true,
                passwordRegex : true
            },
            "password" : {
                required: true,
                passwordRegex : true
            }
        },
        messages: {
            "hospital" : "Please enter your hospital name",
            "user[first_name]" : "Please enter your first name",
            "user[last_name]" : "Please enter your last name",
            "user[email]" : "Please enter a valid email",
            "user[zipcode]" : {
                required : "Please enter your zip code"
            },
            "user[phone]" : {
                required : "Please enter your phone number"
            },
            "user[password]" : {
                required : "Please enter your password"
            }
        },
        errorPlacement: function (error, element) {
            $(element).tooltipster('update', $(error).text());
            $(element).tooltipster('show');
        },
        success: function (label, element) {
            $(element).tooltipster('hide');
        }

    });

    $("#hospital_provider_info_form").validate({
        rules: {
            "hospital[name]" : "required",
            "hospital[address_line1]" : "required",
            "hospital[zipcode]" : {
                required: true,
                zipcodeRegex : "Please provide valid zipcode"
            },
            "hospital[city]" : "required",
            "hospital[phone]" : {
                required: true,
                phoneRegex : true
            },
            "hospital[hospital_identifier]" : {
                required: true,
                numberRegex: true,
                maxlength: 10,
                minlength: 10
            },
            "hospital[website_url]" : {
                websiteRegex: true
            },
            "hospital[image]": {
                extension: "jpg|png|jpeg|gif"
            },
            "hospital[avatar]": {
                extension: "jpg|png|jpeg|gif"
            },
            "hospital[contacts_attributes][0][phone]" : {
                phoneRegex : true
            },
            "hospital[contacts_attributes][0][cell_phone]" : {
                phoneRegex : true
            },
            "hospital[contacts_attributes][0][hospital_email]" : {
                emailRegex : true
            },
            "hospital[contacts_attributes][0][backup_email]" : {
                emailRegex : true
            },
            "hospital[contacts_attributes][1][phone]" : {
                phoneRegex : true
            },
            "hospital[contacts_attributes][1][cell_phone]" : {
                phoneRegex : true
            },
            "hospital[contacts_attributes][1][hospital_email]" : {
                emailRegex : true
            },
            "hospital[contacts_attributes][1][backup_email]" : {
                emailRegex : true
            },
            "hospital[contacts_attributes][2][phone]" : {
                phoneRegex : true
            },
            "hospital[contacts_attributes][2][cell_phone]" : {
                phoneRegex : true
            },
            "hospital[contacts_attributes][2][hospital_email]" : {
                emailRegex : true
            },
            "hospital[contacts_attributes][2][backup_email]" : {
                emailRegex : true
            },
            "hospital[contacts_attributes][3][phone]" : {
                phoneRegex : true
            },
            "hospital[contacts_attributes][3][cell_phone]" : {
                phoneRegex : true
            },
            "hospital[contacts_attributes][2][backup_email]" : {
                emailRegex : true
            },
            "hospital[contacts_attributes][3][backup_email]" : {
                emailRegex : true
            },
            "hospital[contacts_attributes][3][hospital_email]" : {
                emailRegex : true
            }
        },
        messages: {
            "user[name]" : "Please enter hospital name",
            "hospital[image]": "Please upload an image with valid extension",
            "hospital[avatar]": "Please upload an image with valid extension",
            "hospital[address_line1]" : "Please enter address",
            "hospital[hospital_identifier]" : 'Please Enter NPI of Hospital',
            "hospital[zipcode]" : {
                required : "Please enter zipcode"
            },
            "hospital[city]" : {
                required : "Please enter city"
            },
            "hospital[phone]" : {
                required : "Please enter phone"
            }
        },
        errorPlacement: function (error, element) {
            $(element).tooltipster('update', $(error).text());
            $(element).tooltipster('show');
        },
        success: function (label, element) {
            $(element).tooltipster('hide');
        }
    });


    $("#hospital_speciality_info_form").validate({
        rules: {
            "hospital[current_open_postion]" : {
                experienceRegex : true
            },
            "hospital[bba_doc]": {
                extension: "pdf|docx|doc",
                required: true
            },
            "hospital[policy_doc]": {
                extension: "pdf|docx|doc",
                required: true
            },
            "hospital[week_to_setup_contract]" : {
                numberRegex : true,
                maxlength: 2,
                min: 3
            },
            "systems[EHRSystems]":{
                required : true
            },
            "systems[CDISystems]":{
                required : true
            },
            "systems[CACSystems]":{
                required : true
            },
            "systems[CodingSystems]":{
                required : true
            }
        },
        messages: {
            "hospital[bba_doc]": "Please upload Business Associate Agreement document with valid extension",
            "hospital[policy_doc]": "Please upload policy document with valid extension",
            "hospital[week_to_setup_contract]" : {
                maxlength: 'Please enter a two digit number',
                min: 'Minimum is atleast 3 days'
            }
        },

        errorPlacement: function (error, element) {
            $(element).tooltipster('update', $(error).text());
            $(element).tooltipster('show');
        },
        success: function (label, element) {
            $(element).tooltipster('hide');
        }
    });

    $("#hospital_preferences_form").validate({
        onkeyup: function(element) {
            this.element(element);
        },
        rules: {
            "hospital[hospital_preference_attributes][rate_range_min]" : {
                three_digit_two_dec_Regex : true
            },
            "hospital[hospital_preference_attributes][rate_range_max]" : {
                three_digit_two_dec_Regex : true
            },
            "health_care_name": {
                required: true
            },
            "hospital[hospital_preference_attributes][facebook_username]": {
                rangelength: [5, 50],
                facebookUserName : true
            }
        },
        errorPlacement: function (error, element) {
            $(element).tooltipster('update', $(error).text());
            $(element).tooltipster('show');
        },
        success: function (label, element) {
            $(element).tooltipster('hide');
        }
    });

    $("#hospital_profile_infos").validate({
        onkeyup: function(element) {
            this.element(element);
        },
        rules: {
            "hospital[dnfb]" : {
                two_digit_one_dec_Regex : true,
                maxlength: 6,
                required: true
            },
            "hospital[average_a_r_days]" : {
                two_digit_two_dec_Regex : true,
                maxlength: 6,
                required: true
            },
            "hospital[average_a_r_money]" : {
                number: true,
                max: 9999.99,
                required: true
            },
            "hospital[hospital_summary]" : {
                required: true
            },
            "hospital[facility_type_ids]" : {
                required: true
            },
            "hospital[beds_no]" : {
                number: true,
                maxlength: 5,
                required: true
            },
            "hospital[current_census]" : {
                upto100Regex : true,
                required: true
            },
            "hospital[length_of_stay]" : {
                three_digit_two_dec_Regex : true,
                required: true
            },
            "hospital[aculity_level]" : {
                one_digit_two_dec_Regex : true,
                required: true
            }
        },
        messages:{
            "hospital[dnfb]" : {
                maxlength: 'Please provide an input with two digits and one decimal',
            },
            "hospital[beds_no]" : {
                maxlength: 'Please provide an input with four digits'
            },
            "hospital[average_a_r_days]" : {
                maxlength: 'Please provide an input with two digits and two decimal'
            },
            "hospital[current_census]" : {
                upto100Regex : 'Please provide an input upto 100'
            },
            "hospital[length_of_stay]" : {
                three_digit_two_dec_Regex : 'Please provide an input with three digits and two decimal'
            }
        },

        errorPlacement: function (error, element) {
            $(element).tooltipster('update', $(error).text());
            $(element).tooltipster('show');
        },
        success: function (label, element) {
            $(element).tooltipster('hide');
        }
    });

    $('#hospital_average_a_r_money,#hospital_hospital_preference_attributes_rate_range_min, #hospital_hospital_preference_attributes_rate_range_max , #hospital_aculity_level, #hospital_average_a_r_days').on('change', function (){
        num= parseFloat($(this).val());
        $.isNumeric(num) ? $(this).val(num.toFixed(2)) :  $(this).val('')

    });

    $('#hospital_length_of_stay, #hospital_dnfb').on('change', function (){
        num= parseFloat($(this).val());
        $.isNumeric(num) ?  $(this).val(num.toFixed(1)) :  $(this).val('')

    });

    $("#hospital_account_setting_form").validate({
        rules: {
            "user[current_password]" : {
                required: false,
                passwordRegex : true
            },
            "user[password]" : {
                required: false,
                passwordRegex : true,
                hospital_password_not_same: false
            },
            "user[password_confirmation]"  :{
                required: false,
                passwordRegex : true,
                equalTo : "#hospital_setting_new_password"
            },
            "user[secondary_email]" : {
                emailRegex : true,
                email: true
            }
        },
        messages: {
            "user[current_password]" : {
                required:"Please enter your current password"
            },
            "user[password]" : {
                required : "Please enter your new password"
            },
            "user[password_confirmation]" : {
                required:"Please confirm your new password"
            },
            "user[secondary_email]" : {
                emailRegex : "Please provide valid email"
            }
        },
        errorPlacement: function (error, element) {
            $(element).tooltipster('update', $(error).text());
            $(element).tooltipster('show');
        },
        success: function (label, element) {
            $(element).tooltipster('hide');
        }
    });

    $('#hospital_zipcode').keyup(function() {
        $('#hospital_zipcode').valid();
        if ($(this).val().length == 5) {
            var data = {'zip_code': $(this).val()};
            $.ajax({
                url: "/hospital_steps/on_zipcode_change",
                type: "POST",
                data: JSON.stringify(data),
                contentType: 'application/json; charset=utf-8',
                dataType: 'json',
                success: function (data) {
                    if (data.city_state !== null && data.cities !== null){
                        $('#hospital_state').val(data.city_state.state);
                        $('#hospital_state').tooltipster('hide');
                        $('#hospital_cities_list').val('');
                        $.each(data.cities, function(val, text) {
                            if (val == 0)
                                $('#hospital_cities_list').val(text)
                            else
                                $('#hospital_cities_list').val($('#hospital_cities_list').val() + ',' + text)
                        });
                        if (data.city_state.city == "New York City")
                            data.city_state.city = "New York" ;
                        $("#hospital_city").val(data.city_state.city);
                        $("#city_of_zipcode").val(data.city_state.city);
                        $('#hospital_city').autocomplete({
                            lookup: $("#hospital_cities_list").val().split(','),
                            onSelect: function (suggestion) {
                                if($('#city_of_zipcode').val() !== suggestion.value)
                                    $.confirm({
                                        onOpen: function(){
                                            alert('hai');
                                            $("#hospital_provider_info_form .tooltipstered").tooltipster('hide');
                                        },
                                        onClose: function(){
                                            alert('hai');
                                            $("#hospital_provider_info_form .tooltipstered").tooltipster('hide');
                                        },
                                        text: "City is not matching to zipcode",
                                        title: "City confirmation required",
                                        confirmButton: "ok",
                                        cancelButton: false,
                                        confirmButtonClass: "btn-danger",
                                        cancelButtonClass: "btn-default btn-pad clack-hide",
                                        dialogClass: "modal-dialog feb-sm" // Bootstrap classes for large modal
                                    });
                            }
                        });
                    }
                    else{
                        alert('Unable to find a state or city matching zipcode entered')
                    }
                },
                error: function () {
                }
            });
        }
    });

    // $('#user_facility_type_ids_').selectpicker({
    //     liveSearch: true,
    //     liveSearchNormalize: true,
    //     selectedTextFormat: 'count > 2'
    // });

    $('#hospital_setting_current_password, #hospital_setting_new_password').password().on('show.bs.password', function(e) {
        this.nextSibling.name = this.id;
        this.nextSibling.id = "validator_" + this.id;
        let passwordCompare = true;
        if($("#hospital_setting_current_password").val() == "" && $("#hospital_setting_new_password").val() == "") {
            passwordCompare = false;
        }
        $("#validator_" + this.id).rules("add", {
            required: true,
            passwordRegex: true
        });
        if (this.id == 'hospital_setting_new_password'){
            $("#validator_" + this.id).rules("add", {
                hospital_password_not_same: passwordCompare
            });
        }
        $("#validator_" + this.id).tooltipster({
            trigger: 'track',
            onlyOne: false,
            position: 'top',
            animation: 'grow',
            updateAnimation: false,
            delay: 200,
            minWidth: 150,
            maxWidth: 20,
            offsetY: 15
        });
        $("#" + this.id).tooltipster('hide');
        //if($("#"+ this.id).attr('aria-invalid') == "true") {
        //    $("#validator_" + this.id).tooltipster('show');
        //}
    }).on('hide.bs.password', function(e) {
        $(this).parent().find("input").tooltipster({
            trigger: 'track',
            onlyOne: false,
            position: 'right',
            animation: 'grow',
            updateAnimation: false,
            delay: 200,
            minWidth: 150,
            maxWidth: 250,
            offsetX: 40
        });
        $(this).parent().find("input").tooltipster('hide');

    });;
        $('#hospital_setting_confirm_password').password().on('show.bs.password', function(e) {
            this.nextSibling.name = this.id;
            this.nextSibling.id = "validator_" + this.id;
            id = this.id;
            $("#validator_" +this.id).rules("add", {
                required: true,
                passwordRegex : true,
                equalTo: '#hospital_setting_new_password',
            });
            $("#validator_" +this.id).tooltipster({
                trigger: 'track',
                onlyOne: false,
                position: 'top',
                animation: 'grow',
                updateAnimation: false,
                delay: 200,
                minWidth: 150,
                maxWidth: 250
            });
            $("#" + this.id).tooltipster('hide');
            //if($("#"+ this.id).attr('aria-invalid') == "true") {
            //    $("#validator_" + this.id).tooltipster('show');
            //}
        }).on('hide.bs.password', function(e) {
            $(this).parent().find("input").tooltipster({
                trigger: 'track',
                onlyOne: false,
                position: 'right',
                animation: 'grow',
                updateAnimation: false,
                delay: 200,
                minWidth: 150,
                maxWidth: 250,
                offsetX: 40
            });
            $(this).parent().find("input").tooltipster('hide');

        });

    $('#hospital_provider_info_form').submit(function(e){
        if ($("#hospital_state").val() === "") {
            $('#hospital_state').tooltipster('update', "Please enter a valid state");
            $('#hospital_state').tooltipster('show');
            $('#hospital_state').focus();
            e.preventDefault();
        }
    });
});
jQuery(document).ready(function() {
    var offset = ($(document).height() - 500);
    var duration = 500;
    jQuery(document).scroll(function() {
        if (jQuery(this).scrollTop() < ($(document).height() - 1200)) {
            jQuery('.back-to-top').fadeIn(duration);
        } else {
            jQuery('.back-to-top').fadeOut(duration);
        }
    });
});


window.rac_set = function rac_set(status){
    $('#hospital_rac_audit').val(status);
    $('#hospital_rac_audit').trigger('change')
    if(status == true){
    $('#rac_audit').css("display", "inline");}else{
        $('#rac_audit').hide();
    }
}

window.addCommas = function addCommas(nStr) {
    nStr = nStr.replace(/,/g, '').trim();
    nStr += '';
    x = nStr.split('.');
    x1 = x[0];
    x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}
