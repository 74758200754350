$(document).on('turbolinks:load', function() {
    $('#spec_save').click(function (e) {
       $("#spec_type_modal").modal('hide')
       var valuesToSubmit = $('#spec_type_form :input').serialize()
       $.ajax({
          type: "PUT",
          url: $('#spec_url').val(), //sumbits it to the given url of the form
          data: valuesToSubmit,
          dataType: "JSON" // you want a difference between normal and ajax-calls, and json is standard
       }).then(function (json) {
          console.log("success", json);
       });
       return false; // prevents normal behaviour
    });

    $('#open_position_save').click(function (e) {
        $("#myModal").modal('hide')
        var valuesToSubmit = $('#open_position_form :input').serialize()
        $.ajax({
            type: "PUT",
            url: $('#open_position_url').val(), //sumbits it to the given url of the form
            data: valuesToSubmit,
            dataType: "JSON" // you want a difference between normal and ajax-calls, and json is standard
        }).done(function (json) {
            console.log("success", json);
        });
        return false; // prevents normal behaviour
    });

    $(function() {
        $('.number_only').on('keydown', function(e){-1!==$.inArray(e.keyCode,[46,8,9,27,13,110,190])||/65|67|86|88/.test(e.keyCode)&&(!0===e.ctrlKey||!0===e.metaKey)||35<=e.keyCode&&40>=e.keyCode||(e.shiftKey||48>e.keyCode||57<e.keyCode)&&(96>e.keyCode||105<e.keyCode)&&e.preventDefault()});
    })
    $("#hospital_bba_doc").change(function () {
        var file_names = $(this).val().split('\\')
        var file_nme = file_names[file_names.length -1]
        $('#bba_doc').text(file_nme);
        $('#hospital_bba_doc').valid();
    });

    $("#hospital_policy_doc").change(function () {
        var file_names = $(this).val().split('\\')
        var file_nme = file_names[file_names.length -1]
        $('#policy_doc').text(file_nme);
        $('#hospital_policy_doc').valid();
    });

    $('#work_dest').click(function (e) {
        $('#work_dist').text($('#work_distance').val())
        $("#myModal3").modal('hide');
        var valuesToSubmit = $('#myModal3 :input').serialize()
        $.ajax({
            type: "PUT",
            url: $('#specy_url').val(), //sumbits it to the given url of the form
            data: valuesToSubmit,
            dataType: "JSON" // you want a difference between normal and ajax-calls, and json is standard
        }).success(function (json) {
            console.log("success", json);
        });
        return false; // prevents normal behaviour
    });

    $('#work_location').click(function (e) {
        $("#myModal2").modal('hide');
        text_value = ($('#user_work_preference_attributes_work_location').val() == 'Either') ? 'Remote and Onsite' : $('#user_work_preference_attributes_work_location').val();
        $('#work_pre').text(text_value);
        set_work_distance($('#user_work_preference_attributes_work_location').val());
        var valuesToSubmit = $('#myModal2 :input').serialize()
        $.ajax({
            type: "PUT",
            url: $('#specy_url').val(), //sumbits it to the given url of the form
            data: valuesToSubmit,
            dataType: "JSON" // you want a difference between normal and ajax-calls, and json is standard
        }).then(function (json) {
            console.log("success", json);
        });
        return false; // prevents normal behaviour
    });

    $('#aapc_save').click(function (e) {
        make_aapc_as_selected();
        $("#aapc_spec_type_modal").modal('hide')
        var valuesToSubmit = $('#spec_type_form :input').serialize()
        $.ajax({
            type: "PUT",
            url: $('#spec_url').val(), //sumbits it to the given url of the form
            data: valuesToSubmit,
            dataType: "JSON" // you want a difference between normal and ajax-calls, and json is standard
        }).then(function (json) {
            console.log("success", json);
        });
        return false; // prevents normal behaviour
    });

    $('#user_resume').change(function(){
        $('#show-rsm-filename').show();
        $('#show-rsm-filename').html($('#user_resume').val());
    });
    $('#no-resume').click(function () {
        $('#user_resume').val('');
        $('#show-rsm-filename').html($('#user_resume').val());
        $('#user_resume').tooltipster('hide');
    });

    $('#icd_10_coding_year').change(function(){
        if (($('#icd_10_coding_year').val() > 5) || ( $('#icd_10_coding_year').val() == 5 && $('#icd_10_coding_month').val() > 0 )){
            $.confirm({
                text: "Are you sure of your ICD-10 years?",
                title: "ICD-10 confirmation required",
                confirmButton: "ok",
                confirmButtonClass: "btn-danger",
                cancelButtonClass: "btn-default btn-pad clack-hide",
                dialogClass: "modal-dialog feb-sm" // Bootstrap classes for large modal
            });
        }
    });

    $('#icd_10_coding_month').change(function(){
        if ($('#icd_10_coding_year').val() == 5 && $('#icd_10_coding_month').val() > 0 && $('#icd_10_coding_month').val() <=  11){
            $.confirm({
                text: "Are you sure of your ICD-10 years?",
                title: "ICD-10 confirmation required",
                confirmButton: "ok",
                confirmButtonClass: "btn-danger",
                cancelButtonClass: "btn-default btn-pad clack-hide",
                dialogClass: "modal-dialog feb-sm" // Bootstrap classes for large modal
            });
        }
    });

    //var cities = new Array();
    //$('#user_state').change(function () {
    //    window.alert("state is not matching to zip code");
    //    var data = {'state_code': $(this).val()};
    //    $.ajax({
    //        url: "/user_steps/coder_state_city",
    //        type: "POST",
    //        data: JSON.stringify(data),
    //        contentType: 'application/json; charset=utf-8',
    //        dataType: 'json',
    //        success: function (data) {
    //            if (cities.length > 0)
    //                cities.splice(0,cities.length);
    //            $.each(data, function (key, val) {
    //                item = {}
    //                item ["value"] = this.valueOf();
    //                item ["data"] = this.valueOf();
    //                cities.push(item);
    //                //options.append($("<option />").val(this).text(this));
    //            });
    //        },
    //        error: function () {
    //        }
    //    });
    //});


    $('.sign-up-btn-adj').click(function () {
        $('.bs-example-modal-sm').modal('hide');

    });
    $('.crop-btn').click(function () {
        $('.bs-example-modal-sm-cr').modal('hide');
    });

    $('#registration_modal').on('hidden.bs.modal', function () {
        $("#sign_up_form #input_zipcode").tooltipster('hide');
        $("#sign_up_form .error").tooltipster('hide');
    });

    $('#account_setting_modal').on('hidden.bs.modal', function () {
        window.location.reload();
    });

    $('#resend_email_modal').on('hidden.bs.modal', function () {
        $("#resend_email_form .tooltipstered").tooltipster('hide');
    });

    $('#forget_password_modal').on('hidden.bs.modal', function () {
        $("#forget_password_form .tooltipstered").tooltipster('hide');
    });

    $('#reset_password_modal').on('hidden.bs.modal', function () {
        $('*').filter(function() {
            return $(this).data('tooltipsterNs');
        }).tooltipster('hide');
    });

    if ( window.location.pathname === "/users/sign_in" ) {
        $('.bs-example-modal-sm').modal('show');
    }
    if ( window.location.pathname === "/welcome.coder" ) {
        $('.user-already-registered').modal('show');
    }

    if ( window.location.pathname === "/welcome.resend_mail" ) {
        $('.bs-example-modal-re').modal('show');
    }

    if ( window.location.pathname === "/users/password" ) {
        $('.bs-example-modal-forget-password').modal('show');
    }
    if ( window.location.pathname === "/users/password/edit" ) {
        $('.bs-example-modal-edit-password').modal('show');
    }

    if ( window.location.search === "?password_update=false" ) {
        $('#account_setting_modal').modal('show');
    }

    // hide all alert messages after modal close
    $(function() {
        var flashCallback;
        flashCallback = function() {
            return $(".alert").not(".fixed_alert").fadeOut();
        };
        $(".alert").not(".fixed_alert").bind('click', (function() {
            return function() {
                return $(".alert").not(".fixed_alert").fadeOut();
            };
        })(this));
        if ($('.bs-example-modal-sm').hasClass('in')){
            if($(".alert").text().indexOf("has not been activated") >= 0){
                $(".alert").append("<br><a href='#' class= 'resnd-mail-link'  data-dismiss= 'modal' data-toggle='modal' data-target='.bs-example-modal-re'>Resend verification email</a> <br>")
            }
            else{
                return setTimeout(flashCallback, 3000);
            }
        }

    });

    if ($('#user_work_preference_attributes_work_location').length !== 0 && $('#user_work_preference_attributes_work_location').val() !== "Remote" && $('#user_work_preference_attributes_work_location').val() !== "") {
        show_work_preference();
    }
    else if($('#user_work_preference_attributes_work_location').val() === undefined || $('#user_work_preference_attributes_work_location').val() == ''){
        $('#user_work_preference_attributes_work_location').val('Remote');
        $('#general_info_form #remote_btn').addClass('active');
    }


    $(".btn-group > .btn").click(function () {
        $(this).addClass("active").siblings().removeClass("active");
    });


    $('#sign_up_form #input_password').tooltipster({
        trigger: 'track',
        onlyOne: false,
        position: 'right',
        animation: 'grow',
        updateAnimation: false,
        delay: 200,
        minWidth: 150,
        maxWidth: 250,
        offsetX: 40
    });


    $('#sign_up_form input,#sign_up_form select').tooltipster({
        trigger: 'track',
        onlyOne: false,
        position: 'right',
        animation: 'grow',
        updateAnimation: false,
        delay: 200,
        minWidth: 150,
        maxWidth: 250
    });

    $('#general_info_form input,#general_info_form textarea,#general_info_form select').tooltipster({
        trigger: 'track',
        onlyOne: false,
        position: 'top',
        animation: 'grow',
        updateAnimation: false,
        delay: 800
    });

    $('#professional_info_form input, #professional_info_form textarea').tooltipster({
        trigger: 'track',
        onlyOne: false,
        position: 'top-right',
        animation: 'grow',
        updateAnimation: false,
        delay: 800
    });

    $('#credential_educational_info_form input').tooltipster({
        trigger: 'track',
        onlyOne: false,
        position: 'top',
        animation: 'grow',
        updateAnimation: false,
        delay: 800
    });

    $('#speciality_info_form input, #speciality_info_form textarea').tooltipster({
        trigger: 'track',
        onlyOne: false,
        position: 'top',
        animation: 'grow',
        updateAnimation: false,
        delay: 800
    });

    $('#account_setting_form input').tooltipster({
        trigger: 'track',
        onlyOne: false,
        position: 'right',
        animation: 'grow',
        updateAnimation: false,
        delay: 200,
        minWidth: 150,
        maxWidth: 250,
        offsetX: 2
    });

    $('#resend_email_form input').tooltipster({
        trigger: 'track',
        onlyOne: false,
        position: 'top',
        animation: 'grow',
        updateAnimation: false,
        delay: 200
    });

    $("#sign_up_form").validate({
        rules: {
            "user[first_name]" : "required",
            "user[last_name]" : "required",
            "user[email]" : {
                required: true,
                emailRegex: true
            },
            "user[zipcode]" : {
                required: true,
                zipcodeRegex : "Please provide valid zip code"
            },
            "user[password]" : {
                required: true,
                passwordRegex : true
            },
            "password" : {
                required: true,
                passwordRegex : true
            },
            "user[phone]" :{
                required: true,
                phoneRegex: true
            },
        },
        messages: {
            "user[first_name]" : "Please enter your first name",
            "user[last_name]" : "Please enter your last name",
            "user[email]" : "Please enter a valid email",
            "user[zipcode]" : {
                required : "Please enter your zip code"
            },
            "user[phone]" : {
                required : "Please enter your phone number"
            },
            "user[password]" : {
                required : "Please enter your password"
            },
            password:{
                required : "Please enter your password"
            }
        },
        errorPlacement: function (error, element) {
            $(element).tooltipster('update', $(error).text());
            $(element).tooltipster('show');
        },
        success: function (label, element) {
            $(element).tooltipster('hide');
        }

    });

    $("#general_info_form").validate({
        onkeyup: function(element) {
            this.element(element);
        },
        rules: {
            "user[first_name]" : "required",
            "user[last_name]" : "required",
            "user[address_line1]" : "required",
            "user[state]" : 'required',
            "user[city]" : 'required',
            "user[email]" : {
                required: true,
                emailRegex: true
            },
            "user[zipcode]" : {
                required: true,
                zipcodeRegex : "Please provide valid zip code"
            },
            "user[phone]" : {
                phoneRegex : true
            }
        },
        messages: {
            "user[first_name]" : "Please enter your first name",
            "user[last_name]" : "Please enter your last name",
            "user[email]" : "Please enter a valid email",
            "user[state]": "Please enter a valid state",
            "user[city]": "Please enter your city",
            "user[zipcode]" : {
                required : "Please enter your zip code"
            }
        },
        errorPlacement: function (error, element) {
            $(element).tooltipster('update', $(error).text());
            $(element).tooltipster('show');
        },
        success: function (label, element) {
            $(element).tooltipster('hide');
        }
    });


    $("#professional_info_form").validate({
        ignore: "",
        onkeyup: function(element) {
            this.element(element);
        },
        rules: {
            "user[year_as_medical_coder]" :{
                required : true,
                experienceRegex : true,
                range: [1, 50]
            },
            "user[resume]" :{
                extension: "pdf|ppt"
            },
            job_title: {
                required: function(element) {
                    return is_professional_dirty('from_validate');
                }
            },
            institution_name: {
                required: function(element) {
                    return is_professional_dirty('from_validate');
                }
            }
        },
        messages: {
            "user[year_as_medical_coder]" : {
                required: "Please enter your experience"
            },
            "user[resume]" : {
                extension: "Please upload document with pdf extension"
            },
            "job_title" : {
                "required" : "Please enter job title"
            },
            "institution_name" : {
                "required" : "Please enter company name"
            }
        },
        errorPlacement: function (error, element) {
            $(element).tooltipster('update', $(error).text());
            $(element).tooltipster('show');
        },
        success: function (label, element) {
            $(element).tooltipster('hide');
        }
    });

    $('#forget_password_form input').tooltipster({
        trigger: 'track',
        onlyOne: false,
        position: 'top',
        animation: 'grow',
        updateAnimation: false,
        delay: 200
    });

    $('#reset_password_form input').tooltipster({
        trigger: 'track',
        onlyOne: false,
        position: 'left',
        animation: 'grow',
        updateAnimation: false,
        delay: 200,
        minWidth: 150,
        maxWidth: 250
    });

    $("#speciality_info_form").validate({
        onkeyup: function(element) {
            this.element(element);
        },
        rules: {
            "user[year_as_medical_coder]" :{
                required : true,
                experienceRegex : true,
                range: [1, 50]
            },
            "systems[EHRSystems]":{
                required : true
            },
            "systems[CDISystems]":{
                required : true
            },
            "systems[CACSystems]":{
                required : true
            },
            "systems[CodingSystems]":{
                required : true
            }
        },
        messages: {
            "user[year_as_medical_coder]" : {
                required: "Please enter your experience"
            }
        },
        errorPlacement: function (error, element) {
            $(element).tooltipster('update', $(error).text());
            $(element).tooltipster('show');
        },
        success: function (label, element) {
            $(element).tooltipster('hide');
        }
    });

    $("#account_setting_form").validate({
        rules: {
            "user[current_password]" : {
                required: false,
                passwordRegex : true
            },
            "user[password]" : {
                required: false,
                passwordRegex : true,
            },
            "password" :{
                required: false,
                passwordRegex : true,
                password_not_same: true
            },
            "user[password_confirmation]"  :{
                required : false,
                passwordRegex : true,
                equalTo : "#user_new_password"
            },
            "user[secondary_email]" : {
                emailRegex : true,
                email: true
            }
        },
        messages: {
            "user[current_password]" : {
                required:"Please enter your current password"
            },
            "user[password]" : {
                required : "Please enter your new password",
                password_not_same : "Current password should not be equal to new password"
            },
            "user[password_confirmation]" : {
                required:"Please confirm your new password"
            },
            "user[secondary_email]" : {
                emailRegex: "Please provide valid email"
            }
        },
        errorPlacement: function (error, element) {
            $(element).tooltipster('update', $(error).text());
            $(element).tooltipster('show');
        },
        success: function (label, element) {
            $(element).tooltipster('hide');
        }

    });

    $("#credential_educational_info_form").validate({
        rules: {
            "user[icd_10_coding_month]" : {
                range: [0, 11],
                digits: true
            },
            "user[icd_10_coding_year]" : {
                yearRegex : true,
                range: [0, 50]
            },
            school_name: {
                required: function(element) {
                    return is_educational_dirty('from_validate');
                }
            },
            degree: {
                required: function(element) {
                    return is_educational_dirty('from_validate');
                }
            },
            ahima_number:{
                required: function(element){
                  return $("#ahima_number").val() == "";
                },
                ahima_seven_digit: true,
            },
            aapc_number: {
                required: function(element){
                  return $("#aapc_number").val() == "";
                },
                aapc_eight_digit: true,
            }
        },
        messages: {
            "user[icd_10_coding_year]]" : {
                required : "Please enter valid year Expected 0 to 50"
            },
            "user[icd_10_coding_month]]" : {
                required : "Please enter valid month"
            },
            "school_name" : {
                "required" : "Please enter school name"
            },
            "degree" : {
                "required" : "Please enter degree"
            }
        },
        errorPlacement: function (error, element) {
            $(element).tooltipster('update', $(error).text());
            $(element).tooltipster('show');
        },
        success: function (label, element) {
            $(element).tooltipster('hide');
        }
    });

    $("#resend_email_form").validate({
        rules: {
            "user[email]" : {
                required: true,
                emailRegex: "Please provide a valid email"
            }
        },
        messages: {
            "user[email]" : {
                required: "Please provide a valid email"
            }
        },
        errorPlacement: function (error, element) {
            $(element).tooltipster('update', $(error).text());
            $(element).tooltipster('show');
        },
        success: function (label, element) {
            $(element).tooltipster('hide');
        }

    });

    $("#forget_password_form").validate({
        rules: {
            "user[email]" : {
                required : true,
                emailRegex : true
            }
        },
        messages: {
            "user[email]": {
                emailRegex : "Please enter a valid email",
                required: "Please enter a valid email"
            }
        },
        errorPlacement: function (error, element) {
            $(element).tooltipster('update', $(error).text());
            $(element).tooltipster('show');
        },
        success: function (label, element) {
            $(element).tooltipster('hide');
        }
    });

    $("#reset_password_form").validate({
        rules: {
            "user[password]" : {
                required: true,
                passwordRegex : true
            },
            "user[password_confirmation]" : {
                required: true,
                passwordRegex : true,
                equalTo : "#reset_user_password"
            },
            "password" : {
                required: true,
                passwordRegex : true
            }
        },
        messages: {
            "user[password_confirmation]" : {
                equalTo : "confirm password must match the password"
            }
        },
        errorPlacement: function (error, element) {
            $(element).tooltipster('update', $(error).text());
            $(element).tooltipster('show');
        },
        success: function (label, element) {
            $(element).tooltipster('hide');
        }
    });

    $('#confirm_reset_user_password').password().on('show.bs.password', function(e) {
        this.nextSibling.name = 'confirm_password';
        this.nextSibling.id = 'confirm_password';
        $("#confirm_password").rules("add", {
            required: true,
            passwordRegex : true,
            equalTo : "#reset_user_password",
            messages: {
                equalTo: "confirm password must match the password"
            }
        });
        $('#confirm_password').tooltipster({
            trigger: 'track',
            onlyOne: false,
            position: 'left',
            animation: 'grow',
            updateAnimation: false,
            delay: 200,
            minWidth: 150,
            maxWidth: 250
        });

   });

    $("#user_phone, .user_phone").keyup(function(e){
            if(e.keyCode !== 8 && e.keyCode !== 109 &&  e.keyCode !== 189) {
                if ($(this).val().length == 3) {
                    $(this).val($(this).val() + "-");
                } else if ($(this).val().length == 7) {
                    $(this).val($(this).val() + "-");
                }
            }
        });

    $("#user_phone, .user_phone").keydown(function(e){
            if(e.keyCode !== 8 && e.keyCode !== 109 &&  e.keyCode !== 189) {
                if ($(this).val().length == 3) {
                    $(this).val($(this).val() + "-");
                } else if ($(this).val().length == 7) {
                    $(this).val($(this).val() + "-");
                }
            }
            else if (e.keyCode === 189 || e.keyCode === 109){
                mv = $(this).val();
                if (mv.length !== 4 || mv.length !== 7)
                    e.preventDefault();
            }
    });
    $('.likdn').bind('click', function(e){
        e.preventDefault();

        if ($('.professional_summary').val().length > 0){
                $.confirm({
                    text: "This action will overwrite Professional Summary. Are you sure?",
                    title: "Linkedin confirmation required",
                    confirmButton: "Yes I want to overrwrite",
                    cancelButton: 'Cancel',
                    confirm: function () {
                       window.location = '/linkedin_oauth_url'
                    },
                    cancel: function () {
                        // nothing to do
                    },
                    confirmButtonClass: "btn-danger",
                    cancelButtonClass: "btn-default btn-pad",
                    dialogClass: "modal-dialog modal-lg" // Bootstrap classes for large modal
                });
           }
    });

    $('.sys-blank').bind('click', function(e){
         e.preventDefault();
         $.confirm({
             text: "There is no coding systems added till now. Do you want to add coding systems?",
             title: "Systems are empty",
             cancelButton: 'Yes I want to add systems',
             confirmButton: "Cancel",
             confirm: function () {
                 // nothing to do
             },
             cancel: function () {
                 window.location = '/user_steps/speciality_system_infos?profile_return=true'
             },
             confirmButtonClass: "btn-default btn-pad",
             cancelButtonClass: "btn-danger",
             dialogClass: "modal-dialog modal-lg" // Bootstrap classes for large modal
         });
    });


    $('.professional_summary').keyup(function () {
        var value = $(this).val();
        var len = value.replace(/\r\n/g, '~~').replace(/\n/g, '~~').length;
        var left = 450 - len;
        if (left < 0) {
            left = 0;
        }
        $('.chr-lft').text(left+ ' characters left');
    });


    $('#user_zipcode').keyup(function() {
        if ($(this).val().length == 5) {
            var data = {'zip_code': $(this).val()};
            $.ajax({
                url: "/user_steps/on_zipcode_change",
                type: "POST",
                data: JSON.stringify(data),
                contentType: 'application/json; charset=utf-8',
                dataType: 'json',
                success: function (data) {
                    if (data.city_state !== null && data.cities !== null){
                        $('#user_state').val(data.city_state.state);
                        $('#user_state').tooltipster('hide');
                        $('#user_city').tooltipster('hide');
                        $('#coder_cities_list').val('');
                        $.each(data.cities, function(val, text) {
                            if (val == 0)
                                $('#coder_cities_list').val(text)
                            else
                                $('#coder_cities_list').val($('#coder_cities_list').val() + ',' + text)
                        });
                        if (data.city_state.city == "New York City")
                            data.city_state.city = "New York" ;
                        $("#user_city").val(data.city_state.city);
                        $("#city_of_zipcode").val(data.city_state.city);
                        $('#user_city').autocomplete({
                            lookup: $("#coder_cities_list").val().split(','),
                            onSelect: function (suggestion) {
                                if($('#city_of_zipcode').val() !== suggestion.value)
                                    $.confirm({
                                        text: "City is not matching to zipcode",
                                        title: "City confirmation required",
                                        confirmButton: "ok",
                                        cancelButton: false,
                                        confirmButtonClass: "btn-danger",
                                        cancelButtonClass: "btn-default btn-pad clack-hide",
                                        dialogClass: "modal-dialog feb-sm" // Bootstrap classes for large modal
                                    });
                            }
                        });
                    }
                    else{
                        alert('Unable to find a state or city matching zip code entered')
                    }
                },
                error: function () {
                }
            });
        }
    });

    $(document).on('click', '.remove_fields', function(event, p) {
      $(this).prev('input[type=hidden]').val('1');
      if ($(this).parent().parent().hasClass('professional_history')) {
        $(this).closest('.professional_history').hide();
      } else if ($(this).parent().parent().hasClass('education_detail')) {
        $(this).closest('.education_detail').hide();
      }
      return event.preventDefault();

    });

    $(document).on('cocoon:after-insert', function(event, param) {
        if(param.hasClass('professional_history') && $('#job_title').val() !== '' && $('#institution_name').val() !== '') {
            var date_from, date_to;
            if ($('.current_job').hasClass("image-on-small")) {
                var current_job = '&nbsp&nbsp|&nbsp<span class="glyphicon glyphicon-ok green"></span> current job';
                $('.professional_history').last().find('.my_current_job').append(current_job);
                $('.professional_history').last().find('.my_current_job').find('input').val(true);
                $('#professional_his_form .end_date_lbl_div').show();
                $('#professional_his_form .end_date_input_div').show();
                $('#professional_his_form #date_to').datepicker("clearDates");
            }
            $('.professional_history').last().find('.job-title').find('span').text($('#job_title').val());
            $('.professional_history').last().find('.job-title').find('input').val($('#job_title').val());
            $('.professional_history').last().find('.institution_name').find('span').text($('#institution_name').val());
            $('.professional_history').last().find('.institution_name').find('input').val($('#institution_name').val());
            date_from = $('#date_from').val() === '' ? '' : $('#date_from').val() + ' / ';
            if ($('.current_job').hasClass("image-on-small"))
                date_to = 'Present'
            else
                date_to = $('#date_to').val() === '' ? '' : $('#date_to').val();
            $('.professional_history').last().find('.from_to_date').find('span').text(date_from + date_to);
            date_from = $('#date_from').val() === '' ? '' : '01-'+ $('#date_from').val();
            date_to = $('#date_to').val() === '' ? '' :   '01-' + $('#date_to').val();
            $('.professional_history').last().find('.from_to_date').find('input').first().val(date_from);
            $('.professional_history').last().find('.from_to_date').find('input').last().val(date_to);
            $('.professional_history').last().find('.work_summary').find('span').text($('#work_summary').val());
            $('.professional_history').last().find('.work_summary').find('input').val($('#work_summary').val());
            $('#professional_his_form textarea,#professional_his_form input').val('');
            $('#professional_his_form #date_from').datepicker("clearDates");
            $('#professional_his_form #date_to').datepicker("clearDates");
            $('.current_job').removeClass("image-on-small");
            $('#add_professional_histories').attr("disabled", true);
            more_or_less_summary(200);
            $(".nested_user_professional_histories").each(function(i, obj) {
                if ($(this).children().find(".jb-tit").text() == ""){
                 $(this).hide();
                }
            });
            const addedPofession = $('.professional_history').last()
            addedPofession.detach()
            $('.pro-his-list').append(addedPofession);
        }
        if(param.hasClass('education_detail') && $('#school_name').val() !== '' && $('#degree').val() !== '') {
            var date_from,date_to;
            $('.education_detail').last().find('.school-name').find('span').text($('#school_name').val());
            $('.education_detail').last().find('.school-name').find('input').val($('#school_name').val());
            $('.education_detail').last().find('.degree').find('input').val($('#degree').val());
            $('.education_detail').last().find('.degree').find('span').text($('#degree').val());
            date_from  = $('#date_from').val() === '' ? '' : $('#date_from').val() + ' / ';
            date_to = $('#date_to').val() === '' ?  '' : $('#date_to').val();
            $('.education_detail').last().find('.from_to_date').find('span').text(date_from + date_to);
            date_from  = $('#date_from').val() === '' ?  '' : '01-'+ $('#date_from').val() ;
            date_to  = $('#date_to').val() === '' ? ''  : '01-' + $('#date_to').val();
            $('.education_detail').last().find('.from_to_date').find('input').first().val(date_from);
            $('.education_detail').last().find('.from_to_date').find('input').last().val(date_to);
            $('#credential_educational_form input').val('');
            $('#add_educational_histories').attr("disabled", true);
            $(".education_detail").each(function(i, obj) {
                if ($(this).children().find(".jb-tit").text() == ""){
                    $(this).hide();
                }
            });
            const addedEducation = $('.education_detail').last()
            addedEducation.detach()
            $('.education-list').append(addedEducation);
        }
    });

    $(document).on('click', '.btn_edit_professional_his', function(e){
        e.preventDefault()
        var current_job;
        $('.bs-example-modal-ph').modal('show');
        $('#modal_job_title').val($(this).closest('.professional_history').find('.job-title').find('span').text());
        $('#modal_institution_name').val($(this).closest('.professional_history').find('.institution_name').find('span').text());
        if ($(this).closest('.professional_history').find('.from_to_date').find('input').first().val().length == 10){
            start_date = $(this).closest('.professional_history').find('.from_to_date').find('input').first().val().substr(3, 10)
        }
        else {
            start_date = $(this).closest('.professional_history').find('.from_to_date').find('input').first().val().substr(0, 7);
            start_date = get_date_format(start_date);
        }
        $('#modal_date_from').val(start_date);
        $("#modal_date_from").datepicker("update",start_date);
        if ($(this).closest('.professional_history').find('.from_to_date').find('input').last().val().length == 10){
            end_date = $(this).closest('.professional_history').find('.from_to_date').find('input').last().val().substr(3, 10)
        }
        else {
            end_date = $(this).closest('.professional_history').find('.from_to_date').find('input').last().val().substr(0, 7);
            end_date = get_date_format(end_date);
        }
        $('#modal_date_to').val(end_date);
        $("#modal_date_to").datepicker("update",end_date);
        if( $(this).closest('.professional_history').find('.work_summary').find('span').text().length > 200) {
            summary = $(this).closest('.professional_history').find('.work_summary').find('span').text().slice(0, 200) + $(this).closest('.professional_history').find('.work_summary').find('span').last().text().replace(/\Less/g,"").replace(/\... More/g,"");
            $('#modal_work_summary').val(summary);
            $(this).closest('.professional_history').find('.work_summary').find('span').children().remove();
        }
        else{
            $('#modal_work_summary').val($(this).closest('.professional_history').find('.work_summary').find('span').text());
        }
        current_job = $(this).closest('.professional_history').find('.my_current_job').find('input').val();
        if (current_job === "true"){
            $('.modal_current_job').addClass('image-on-small');
            $('#professional_modal .end_date_lbl_div').hide();
            $('#professional_modal .end_date_input_div').hide();
        }
        else{
            $('#professional_modal .end_date_lbl_div').show();
            $('#professional_modal .end_date_input_div').show();
        }
        $('.update_professional_history').removeAttr("disabled");
        $(this).closest('.professional_history').addClass('editing_professional_history');
        $('#professional_info_form').find('.tooltipstered').tooltipster('hide');
        $('#prof_history_id').val($(this).closest('.professional_history').find('#prof_his_id').val());

    });

    $(document).on('click', '.btn_edit_educational_his', function(e){
        e.preventDefault()
        var current_job;
        $('.bs-example-modal-eh').modal('show');
        $('#modal_school_name').val($(this).closest('.education_detail').find('.school-name').find('span').text());
        $('#modal_degree').val($(this).closest('.education_detail').find('.degree').find('span').text());

        if ($(this).closest('.education_detail').find('.from_to_date').find('input').first().val().length == 10){
            start_date = $(this).closest('.education_detail').find('.from_to_date').find('input').first().val().substr(3, 10)
        }
        else {
            start_date = $(this).closest('.education_detail').find('.from_to_date').find('input').first().val().substr(0, 7);
            start_date = get_date_format(start_date);
        }
        $('#modal_date_from').val(start_date);
        $("#modal_date_from").datepicker("update",start_date);
        if ($(this).closest('.education_detail').find('.from_to_date').find('input').last().val().length == 10){
            end_date = $(this).closest('.education_detail').find('.from_to_date').find('input').last().val().substr(3, 10)
        }
        else {
            end_date = $(this).closest('.education_detail').find('.from_to_date').find('input').last().val().substr(0, 7);
            end_date = get_date_format(end_date);
        }

        $('#modal_date_to').val(end_date);
        $("#modal_date_to").datepicker("update",end_date);
        $('.update_educational_history').removeAttr("disabled");
        $(this).closest('.education_detail').addClass('editing_education_detail');
        $('#credential_educational_info_form').find('.tooltipstered').tooltipster('hide');
        $('#edu_history_id').val($(this).closest('.education_detail').find('#edu_his_id').val());
    });

    $('.update_professional_history').click(function(){
        var date_from,date_to;
        if ($('.modal_current_job').hasClass("image-on-small") && $('.editing_professional_history').find('.my_current_job').find('input').val() !== "true"){
            var current_job =  '&nbsp&nbsp|&nbsp<span class="glyphicon glyphicon-ok green"></span> current job';
            $('.editing_professional_history').find('.my_current_job').append(current_job);
            $('.editing_professional_history').find('.my_current_job').find('input').val(true);
        }
        else if (!$('.modal_current_job').hasClass("image-on-small") && $('.editing_professional_history').find('.my_current_job').find('input').val() === "true"){
            $('.editing_professional_history').find('.my_current_job').find('span').remove();
            $('.editing_professional_history').find('.my_current_job').contents().filter(function(){ return this.nodeType !== 1; }).remove();
            $('.editing_professional_history').find('.my_current_job').find('input').val(false);
        }
        $('.editing_professional_history').find('.job-title').find('span').text($('#modal_job_title').val());
        $('.editing_professional_history').find('.job-title').find('input').val($('#modal_job_title').val());
        $('.editing_professional_history').find('.institution_name').find('span').text($('#modal_institution_name').val());
        $('.editing_professional_history').find('.institution_name').find('input').val($('#modal_institution_name').val());
        date_from  = $('#modal_date_from').val() === '' ? '' : $('#modal_date_from').val() + ' / ';
        if ($('.modal_current_job').hasClass("image-on-small"))
            date_to = 'Present'
        else
            date_to = $('#modal_date_to').val() === '' ?  '' : $('#modal_date_to').val();
        $('.editing_professional_history').find('.from_to_date').find('span').text(date_from + date_to);
        date_from  = $('#modal_date_from').val() === '' ?  '' :  '01-' + $('#modal_date_from').val();
        date_to  = $('#modal_date_to').val() === '' ? ''  : '01-' + $('#modal_date_to').val();
        $('.editing_professional_history').find('.from_to_date').find('input').first().val(date_from);
        $('.editing_professional_history').find('.from_to_date').find('input').last().val(date_to);
        $('.editing_professional_history').find('.work_summary').find('span').text($('#modal_work_summary').val());
        $('.editing_professional_history').find('.work_summary').find('input').val($('#modal_work_summary').val());
        $('.editing_professional_history').removeClass('editing_professional_history');
        $('.bs-example-modal-ph').modal('hide');
        more_or_less_summary(200);
    });

    $('.update_educational_history').click(function(){
        var date_from,date_to;
        $('.editing_education_detail').find('.school-name').find('span').text($('#modal_school_name').val());
        $('.editing_education_detail').find('.school-name').find('input').val($('#modal_school_name').val());
        $('.editing_education_detail').find('.degree').find('span').text($('#modal_degree').val());
        $('.editing_education_detail').find('.degree').find('input').val($('#modal_degree').val());
        date_from  = $('#modal_date_from').val() === '' ? '' : $('#modal_date_from').val() + ' / ';
        date_to = $('#modal_date_to').val() === '' ?  '' : $('#modal_date_to').val();
        $('.editing_education_detail').find('.from_to_date').find('span').text(date_from + date_to);
        date_from  = $('#modal_date_from').val() === '' ?  '' : '01-' + $('#modal_date_from').val();
        date_to  = $('#modal_date_to').val() === '' ? ''  : '01-' + $('#modal_date_to').val();
        $('.editing_education_detail').find('.from_to_date').find('input').first().val(date_from);
        $('.editing_education_detail').find('.from_to_date').find('input').last().val(date_to);
        $('.editing_education_detail').removeClass('editing_education_detail');
        $('.bs-example-modal-eh').modal('hide');
    });


    $('#professional_modal').on('hidden.bs.modal', function () {
        $('#professional_info_form').find('.error').tooltipster('show');
    });

    $('#educational_modal').on('hidden.bs.modal', function () {
        $('#credential_educational_info_form').find('.error').tooltipster('show');
    });

    $('#credential_educational_info_form .certification .image-off-small').click(function(){
        if ($(this).hasClass('image-on-small')){
            $(this).parent().find('input').val($(this).parent().find('input').val() + '_selected');
        }
        else{
            $(this).parent().find('input').val().replace('_selected','');
            $(this).parent().find('input').val( $(this).parent().find('input').val().replace('_selected',''));
        }
        $(this).parent().find('input').trigger('change')
    });


    $('.speciality_save').click(function(){
        $('.spec_list ul').empty();
        $('.spec_type').each(function(i, obj) {
            if ($(this).children().hasClass('image-on-small')){
                if($(this).children().find('input').prop("checked") === false)
                    $(this).children().find('input').prop("checked",true);
                list= $(this).children().text();
                id = $(this).children().find('input').attr('value');
                aapc_list = '<li><span class="label label-info"> <a class="no_confirm_exit" href="javascript:void(0);" onclick="remove_spec_type(this);"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span> </a></span> </li>';
                $('.spec_list ul').append(aapc_list);
                $('.spec_list ul li').last().find('span').first().prepend(list);
                $('.spec_list ul li').last().attr('id', id);
            }
            else{
                $(this).children().find('input').prop("checked",false);
                $(this).children().find('input').trigger('change')
            }
            //test
        });
        tinysort("ul#new_spec_list>li");
    });

    $('#aapc_spec_type_modal').on('show.bs.modal', function () {
        $('#credential_educational_info_form').find('.tooltipstered').tooltipster('hide');
    });

    $('#aapc_spec_type_modal').on('hidden.bs.modal', function () {
        $('.aapc_spec_type').each(function(i, obj) {
            if($(this).children().find('input').val().indexOf("_selected") >= 0 )
                $(this).children().first().addClass('image-on-small');
            else
              $(this).children().first().removeClass('image-on-small');
        });
        $('#credential_educational_info_form').find('.error').tooltipster('show');
    });

    $('#credential_educational_info_form .has_icd_10_training').click(function(){
        if ($(this).find('.active').text() === "NO") {
            $(this).find('input').val(false);
        }
        else {
            $(this).find('input').val(true);
        }
        $(this).find('input').trigger('change');
    });

    $('#credential_educational_info_form .has_icd_10_coding').click(function(){
        if ($(this).find('.active').text() === "NO") {
            $('.icd_10_training_date_div').hide();
            $(this).find('input').val(false);
            $('#icd_10_coding_year').val('');
            $('#icd_10_coding_month').val('');
        }
        else {
            $('.icd_10_training_date_div').show();
            $(this).find('input').val(true);
        }
        $(this).find('input').trigger('change');
    });

    $('#professional_his_form .current_job').click(function (){
        if ($(this).hasClass('image-on-small')){
            $('#professional_his_form .end_date_lbl_div').hide();
            $('#professional_his_form .end_date_input_div').hide();
            $('#professional_his_form #date_to').datepicker("clearDates");
            $("#professional_his_form #date_to").datepicker("update", $('#professional_his_form #date_from').val());
            $(this).find('input').val(true);
        }
        else{
            $('#professional_his_form .end_date_lbl_div').show();
            $('#professional_his_form .end_date_input_div').show();
            $(this).find('input').val(false);
        }
        is_professional_dirty('from_check_click');
    });

    $('#professional_modal .modal_current_job').click(function (){
        if ($(this).hasClass('image-on-small')){
            $('#professional_modal .end_date_lbl_div').hide();
            $('#professional_modal .end_date_input_div').hide();
            $('#professional_modal #modal_date_to').val($('#professional_modal #modal_date_from').val());
            $('#professional_modal #modal_date_to').datepicker("update", $('#professional_modal #modal_date_from').val());
        }
        else{
            $('#professional_modal .end_date_lbl_div').show();
            $('#professional_modal .end_date_input_div').show();
        }
    });


    $('.resend_email').click(function(){
        $('.bs-example-modal-lg').modal('hide');
    });

    $('.sign_up_in_resend').click(function(){
        if ($('#from_which_form').val() === 'hospital-reg')
            $('.bs-example-modal-lg-hospitalsignup').modal('show');
        else if ($('#from_which_form').val() === 'coder-reg')
            $('.bs-example-modal-lg').modal('show');
        $('.bs-example-modal-re').modal('hide');
    });

    $('.forget_psw_link').click(function(){
        $('.bs-example-modal-sm').modal('hide');
    });

    $('.sigup_link_from_forget_psw, .sigin_link_from_forget_psw').click(function(){
        if ($('.bs-example-modal-forget-password').hasClass('in'))
             $('.bs-example-modal-forget-password').modal('hide');
        else if ($('.bs-example-modal-edit-password').hasClass('in'))
            $('.bs-example-modal-edit-password').modal('hide');
    });

    if ($('#credential_educational_info_form #user_has_icd_10_training').val() === 'true' ) {
        $('#credential_educational_info_form #user_has_icd_10_training').parent().find('button').first().addClass('active');
    }
    else if ($('#credential_educational_info_form #user_has_icd_10_training').val() === 'false' || $('#credential_educational_info_form #user_has_icd_10_training').val() === '') {
        $('#credential_educational_info_form #user_has_icd_10_training').parent().find('button').last().addClass('active');
        $('#credential_educational_info_form #user_has_icd_10_training').val(false);
    }

    if ($('#credential_educational_info_form #user_has_icd_10_coding').val() === 'true' ) {
        $('#credential_educational_info_form #user_has_icd_10_coding').parent().find('button').first().addClass('active');
    }
    else if ($('#credential_educational_info_form #user_has_icd_10_coding').val() === 'false' || $('#credential_educational_info_form #user_has_icd_10_coding').val() === '') {
        $('#credential_educational_info_form #user_has_icd_10_coding').parent().find('button').last().addClass('active');
        $('#credential_educational_info_form #user_has_icd_10_coding').val(false);
    }

    if ( window.location.pathname === "/user_steps/professional_infos") {
        more_or_less_summary(200);
    }


    $(document).on('click', '.more', function(event){
        event.preventDefault();
        $(this).hide().prev().hide();
        $(this).next().show();
    });

    $(document).on('click', '.less', function(event){
        event.preventDefault();
        $(this).parent().hide().prev().show().prev().show();
    });

    if ($('#credential_educational_info_form #user_has_icd_10_coding').val()== 'true')
        $('.icd_10_training_date_div').show();
    else
        $('.icd_10_training_date_div').hide();

    //$("#work_preference_form .by_houry").bind("keyup keydown paste",set_hour_fun);

    if ($('#work_preference_form #user_work_preference_attributes_less_hour_works').val() === ' ' ) {
        $('#credential_educational_info_form .user_work_preference_less_hour_works').parent().find('button').last().addClass('active');
        $('#work_preference_form #user_work_preference_attributes_less_hour_works').val(false);
    }

    $('#professional_his_form input, #professional_his_form textarea').on('keyup', (function(event) {
        if( $(this).val().trim() == '')
            $(this).val($(this).val().trim());
        is_professional_dirty('from_event');
    }));

    $('#modal_professional_his_form input, #professional_his_form textarea').on('keyup', (function(event) {
        if( $(this).val().trim() == '')
            $(this).val($(this).val().trim());
        is_modal_professional_update_disable();
    }));

    $('#credential_educational_form input').on('keyup', (function(event) {
        if( $(this).val().trim() == '')
            $(this).val($(this).val().trim());
        is_educational_dirty('from_event');
    }));

    $('#modal_educational_his_form input').on('keyup', (function(event) {
        if( $(this).val().trim() == '')
            $(this).val($(this).val().trim());
        is_modal_educational_update_disable();
    }));

    $('#general_info_form').submit(function(e){
        if ($("#user_state").val() === "") {
            $("#user_state").rules("add", {
                required: true,
                messages: {
                    required: "Please enter a valid state"
                }
            });
            $('#user_state').tooltipster('update', "Please enter a valid state");
            $('#user_state').tooltipster('show');
            e.preventDefault();
        }
    });

    $('#professional_info_form').submit(function(){
        if ($('#add_professional_histories').attr('disabled') === 'disabled'){
            if ( ($('#date_from').val() !== '' || $('#date_to').val() !== '') && ($('#job_title').val() == '' && $('#institution_name').val() == '' && $('#work_summary').val() == '' && !$('.current_job').hasClass('image-on-small')) ){
                $('#date_from').datepicker("clearDates");
                $('#date_to').datepicker("clearDates");
                $('#job_title').tooltipster('hide');
                $('#institution_name').tooltipster('hide');
                if(!$('#user_resume').hasClass('error') && !$('#user_year_as_medical_coder').hasClass('error'))
                    $(this).submit();
            }
        }
        $('#add_professional_histories').attr("disabled", true);
    });

    $('#credential_educational_info_form').submit(function(){
        if ($('#add_educational_histories').attr('disabled') === 'disabled'){
            if ( ($('#date_from').val() !== '' || $('#date_to').val() !== '') && ($('#school_name').val() == '' && $('#degree').val() == '') ){
                $('#date_from').datepicker("clearDates");
                $('#date_to').datepicker("clearDates");
                $('#school_name').tooltipster('hide');
                $('#degree').tooltipster('hide');
                if(!$('#icd_10_coding_year').hasClass('error') && !$('#icd_10_coding_month').hasClass('error'))
                    this.submit();
            }
        }
        $('#add_educational_histories').attr("disabled", true);
    });

    $('.edit_user_image').click(function(){
        $(this).parent().parent().parent().parent().parent().find('form .tooltipstered').tooltipster('hide');
        $('#data-popover').popover('hide');
    });

    $('.open-pos').click(function(){
        $(this).parent().parent().parent().parent().parent().find('.tooltipstered').tooltipster('hide');
    });

    $('.resnd-mail-link').click(function(){
        //$('.bs-example-modal-re').modal('show');
        $('#from_which_form').val($(this).attr('id'));
    });

    $('.sigin-already-reg-user').click(function(){
        $('.user-already-registered').modal('hide');
    });

    $('.registration_input_password').password().on('show.bs.password', function(e) {
        $(this).parent().find('#input_password').tooltipster({
            trigger: 'track',
            onlyOne: false,
            position: 'right',
            animation: 'grow',
            updateAnimation: false,
            delay: 200,
            minWidth: 150,
            maxWidth: 250,
            offsetX: 40
        });
        $(this).parent().find("[name='password']").tooltipster({
            trigger: 'track',
            onlyOne: false,
            position: 'right',
            animation: 'grow',
            updateAnimation: false,
            delay: 200,
            minWidth: 150,
            maxWidth: 250,
            offsetX: 40
        });
        $(this).parent().find('#input_password').tooltipster('hide');
    }).on('hide.bs.password', function(e) {
        $(this).parent().find("[name='password']").tooltipster({
            trigger: 'track',
            onlyOne: false,
            position: 'right',
            animation: 'grow',
            updateAnimation: false,
            delay: 200,
            minWidth: 150,
            maxWidth: 250,
            offsetX: 40
        });
        $(this).parent().find('#input_password').tooltipster({
            trigger: 'track',
            onlyOne: false,
            position: 'right',
            animation: 'grow',
            updateAnimation: false,
            delay: 200,
            minWidth: 150,
            maxWidth: 250
        });
        $(this).parent().find("[name='password']").tooltipster('hide');
    });

    $('.other_system_input_chk').click(function (e) {
        if (!$(this).hasClass('image-on-small')){
            $(this).parent().find('input').val('');
        }
    });

    $('#user_current_password, #user_new_password').password().on('show.bs.password', function(e) {
        this.nextSibling.name = this.id;
        this.nextSibling.id = "validator_" + this.id;
        let passwordCompare = true;
        if($("#hospital_setting_current_password").val() == "" && $("#hospital_setting_new_password").val() == "") {
            passwordCompare = false;
        }
        $("#validator_" + this.id).rules("add", {
            required: true,
            passwordRegex: true
        });
        if (this.id == 'user_new_password'){
            $("#validator_" + this.id).rules("add", {
                password_not_same: passwordCompare
            });
        }

        $("#validator_" + this.id).tooltipster({
            trigger: 'track',
            onlyOne: false,
            position: 'top',
            animation: 'grow',
            updateAnimation: false,
            delay: 200,
            minWidth: 150,
            maxWidth: 250
        });
        $("#" + this.id).tooltipster('hide');
        //if($("#"+ this.id).attr('aria-invalid') == "true") {
        //    $("#validator_" + this.id).tooltipster('show');
        //}
    }).on('hide.bs.password', function(e) {
        $(this).parent().find("input").tooltipster({
            trigger: 'track',
            onlyOne: false,
            position: 'right',
            animation: 'grow',
            updateAnimation: false,
            delay: 200,
            minWidth: 150,
            maxWidth: 250,
            offsetX: 40
        });
        $(this).parent().find("input").tooltipster('hide');

    });;
    $('#user_confirm_password').password().on('show.bs.password', function(e) {
        this.nextSibling.name = this.id;
        this.nextSibling.id = "validator_" + this.id;
        id = this.id;
        $("#validator_" +this.id).rules("add", {
            required: true,
            passwordRegex : true,
            equalTo: '#user_new_password'
        });
        $("#validator_" +this.id).tooltipster({
            trigger: 'track',
            onlyOne: false,
            position: 'top',
            animation: 'grow',
            updateAnimation: false,
            delay: 200,
            minWidth: 150,
            maxWidth: 250
        });
        $("#" + this.id).tooltipster('hide');
        //if($("#"+ this.id).attr('aria-invalid') == "true") {
        //    $("#validator_" + this.id).tooltipster('show');
        //}
    }).on('hide.bs.password', function(e) {
        $(this).parent().find("input").tooltipster({
            trigger: 'track',
            onlyOne: false,
            position: 'right',
            animation: 'grow',
            updateAnimation: false,
            delay: 200,
            minWidth: 150,
            maxWidth: 250,
            offsetX: 40
        });
        $(this).parent().find("input").tooltipster('hide');

    });



});

function is_professional_dirty(state){
    var dirty = false;
    $('#professional_his_form input, #professional_his_form textarea').each(function () {
        if ($(this).val() !== '' && ( $('#job_title').val() == '' || $('#institution_name').val() == '' ) && $(this).attr('id') !== 'is_current_job')
            dirty = true;
        else if($(this).val() === 'true' && $(this).attr('id') == 'is_current_job'&& ( $('#job_title').val() == '' || $('#institution_name').val() == '' ))
            dirty = true;
    });
    if (dirty === false && state === 'from_check_click' &&  ( $('#job_title').val() == '' || $('#institution_name').val() == '' )){
        if (!$(this).hasClass('image-on-small')){
            $('#job_title').tooltipster('hide');
            $('#institution_name').tooltipster('hide');
        }
        dirty = true;
    }

    if (dirty == false) {
        $('#job_title').tooltipster('hide');
        $('#institution_name').tooltipster('hide');
        if ($('#job_title').val() !== '' && $('#institution_name').val() !== '')
        $('#add_professional_histories').removeAttr("disabled");
    }
    else {
        $('#add_professional_histories').attr("disabled", true);
    }

    if (state == 'from_validate')
        return dirty;
}

function is_modal_professional_update_disable(){
    var dirty = false;
    $('#modal_professional_his_form input, #modal_professional_his_form textarea').each(function () {
        if ( $('#modal_job_title').val() == '' || $('#modal_institution_name').val() == ''  && $(this).attr('id') !== 'modal_current_job')
            dirty = true;
        else if($(this).val() === 'true' && $(this).attr('id') == 'modal_current_job'&& ( $('#modal_job_title').val() == '' || $('#modal_institution_name').val() == '' ))
            dirty = true;
    });
    if (dirty == false) {
        $('.update_professional_history').removeAttr("disabled");
    }
    else {
        $('.update_professional_history').attr("disabled", true);
    }
}

function is_educational_dirty(state){
    var dirty = false;
    $('#credential_educational_form input').each(function () {
        if ($(this).val() !== '' && ( $('#school_name').val() == '' || $('#degree').val() == '' ))
            dirty = true;
    });

    if (dirty == false) {
        $('#school_name').tooltipster('hide');
        $('#degree').tooltipster('hide');
        if ($('#school_name').val() !== '' && $('#degree').val() !== '')
        $('#add_educational_histories').removeAttr("disabled");
    }
    else {
        $('#add_educational_histories').attr("disabled", true);
    }

    if (state == 'from_validate')
        return dirty;
}

function is_modal_educational_update_disable(){
    var dirty = false;
    $('#modal_educational_his_form input').each(function () {
        if ( $('#modal_school_name').val() == '' || $('#modal_degree').val() == ''  )
            dirty = true;
    });
    if (dirty == false) {
        $('.update_educational_history').removeAttr("disabled");
    }
    else {
        $('.update_educational_history').attr("disabled", true);
    }
}


function show_work_preference(){
    $('#welcomeDiv').show();
    $('#show-fly').show();
    $("#work_distance_slider").slider({
        tooltip: 'always'
    });
    $("#work_distance_slider").on("slide", function (slideEvt) {
        $("#ex6SliderVal").text(slideEvt.value);
    });
}

window.work_prefernce  = function work_prefernce(status){
    $('#user_work_preference_attributes_work_location').val(status);
    $('#user_work_preference_attributes_work_location').trigger('change')

    $('#work_distance_slider').attr('data-slider-value','20')
    if (status !== "Remote") {
        show_work_preference();
    }
    else{
        $('#welcomeDiv').hide();
        $('#show-fly').show();
    }
}

window.set_value_for = function set_value_for(value_id, status){
    $(value_id).val(status);
    $(value_id).trigger('change')
}

window.will_fly = function will_fly(status) {
    $('#user_work_preference_attributes_willing_to_fly').val(status);
    $('#user_work_preference_attributes_willing_to_fly').trigger('change')
}

window.remove_spec_type = function remove_spec_type(elem){
    id = $(elem).closest('li').attr('id');
    if ($('.spec_type').find('input[type=checkbox][value='+id+']').closest('.spec_type').find('.image-on-small').length == 1)
        $('.spec_type').find('input[type=checkbox][value='+id+']').closest('.spec_type').find('.image-on-small').trigger('click');
    $(elem).closest('li').remove();
}

window.remove_aapc_spec_type = function remove_aapc_spec_type(elem){
    id = $(elem).closest('li').attr('id');
    $('.aapc_spec_type').find('input#'+id).trigger('change')
    $('.aapc_spec_type').find('input#'+id).val($('.aapc_spec_type').find('input#'+id).val().replace('_selected',''));
    if ($('.aapc_spec_type').find('input#'+id).closest('.aapc_spec_type').find('.image-on-small').length == 1)
        $('.aapc_spec_type').find('input#'+id).closest('.aapc_spec_type').find('.image-on-small').removeClass('image-on-small');
    $(elem).closest('li').remove();
}
$(document).on('turbolinks:load', function() {

    $('.by_hour_traves').change(function(){
        $(this).addClass('has_value');
    });

    if(!$("#work_hours").hasClass('ui-slider')) {
      $("#work_hours").slider({ tooltip: 'always' });
      $("#work_hours").addClass('ui-slider')
    }

    $("#work_distance").slider({
        tooltip: 'always'
    });
    $("#work_hours").on("slide", function (slideEvt) {
        $("#ex6SliderVal").text(slideEvt.value);
    });
    // $('#user_speciality_type_ids_').selectpicker({
    //     enableFiltering: true,
    //     enableCaseInsensitiveFiltering: true,
    //     numberDisplayed: 1
    //  });

    $('.sign-in-password-div').find('span i').css("position", 'relative');
    $('.sign-in-password-div').find('span').css("padding", '0');
});

window.make_checkbox_checked = function make_checkbox_checked(checkId) {
  let checkbox = $(`#special${checkId}`).find("input[type='checkbox']");
  $(checkbox).trigger("change");
  if (checkbox.prop("checked")) {
    checkbox.prop("checked", false);
  } else {
    checkbox.prop("checked", true);
  }
};

window.onClickCheckBox = function onClickCheckBox(fieldName, imageId) {
    var elementId = '#' + fieldName;
    if($(elementId).is(':checked')){
        $(elementId).attr('checked', false);
    }
    else {
        $(elementId).attr('checked', true);
    }
    changeCheckBoxImageClass(imageId);
}

window.changeCheckBoxImageClass = function changeCheckBoxImageClass(imageId) {
    var elementId = '#'+ imageId;
    if($(elementId).hasClass('image-off-small')) {
        $(elementId).removeClass('image-off-small').addClass('image-on-small');
    }
    else if($(elementId).hasClass('image-on-small')) {
        $(elementId).removeClass('image-on-small').addClass('image-off-small');
    }
    else if($(elementId).hasClass('image-off')) {
        $(elementId).removeClass('image-off').addClass('image-on');
    }
    else if($(elementId).hasClass('image-on')) {
        $(elementId).removeClass('image-on').addClass('image-off');
    }
}

window.setValueInCheckBox = function setValueInCheckBox(fromId, toId) {
    fromId = '#' + fromId;
    toId = '#' + toId;
    if(($(fromId)[0]) && ($(toId)[0])) {
        var value = $(toId).val();
        value = value + " : " + $(fromId).val();
        $(toId).val(value);
    }
}

window.showTextArea = function showTextArea(triggerId, textAreaId) {
    var element = '#' + textAreaId;
    var triggerElement = '#' + triggerId;
    if($(triggerElement).hasClass('image-off-small')) {
        $(element).val('');
    }
}

window.more_or_less_summary =  function more_or_less_summary(len){
    var minimized_elements = $('span.minimize');
    minimized_elements.each(function(){
        var t = $(this).text();
        var length = len;
        var adjusting_length = 0
        if ( window.location.pathname.indexOf("hospital_dashboard") >= 0 )
            adjusting_length = 30;
        else
            adjusting_length = 20;

        length = $(this).parent().hasClass("description_td" ) ? length + adjusting_length : length + 5;
        if(t.length < length) return;
        if (!$(this).parent().hasClass( "split" )){
            var n = t.slice(0,length).split(" ");
             if ( n[n.length - 1].length > 7)
               length = length - 5;
            $(this).html(
                t.slice(0,length)+'<span>... </span><a href="#" class="more read-mr-link" style = "text-transform: none">more</a>'+
                '<span style="display:none;">'+ t.slice(length,t.length)+' <a href="#" class="less read-mr-link" style = "text-transform: none">less</a></span>'
            );
        }

        $(this).parent().addClass('split');

    });
}

window.more_or_less_summary_text = function more_or_less_summary_text(len){
    var minimized_elements = $('span.text-dimensioning');
    minimized_elements.each(function(){
        var t = $(this).text();
        var length = len;
        var adjusting_length = 0
        if ( window.location.pathname.indexOf("hospital_dashboard") >= 0 )
            adjusting_length = 30;
        else
            adjusting_length = 20;

        length = 68;
        if(window.innerWidth < 1200){
            length = 52;
        }
        if(window.innerWidth < 991){
            length = 20;
        }


        if(t.length < length) return;
        if (!$(this).parent().hasClass( "split" )){
            var n = t.slice(0,length).split(" ");
             if ( n[n.length - 1].length > 7)
               length = length - 5;
            $(this).html(
                t.slice(0,length)+'<span>... </span><a href="#" class="more read-mr-link" style = "text-transform: none">more</a>'+
                '<span style="display:none;">'+ t.slice(length,t.length)+' <a href="#" class="less read-mr-link" style = "text-transform: none">less</a></span>'
            );
        }

        $(this).parent().addClass('split');

    });
}

window.set_work_distance = function set_work_distance(value){
    if(value == 'Remote'){
        $slider = $("#work_distance")
        $slider.slider('setValue', 0);
        $('#work_dist').text($('#work_distance').val());
        $("#location_text").hide();
    }
    else{
        $("#location_text").show();
    }
}

window.get_date_format = function get_date_format(date){
    if (date != '') {
        date_arr = date.split('-');
        return date_arr[1] + '-' + date_arr[0];
    }
}

window.set_hour_fun = function set_hour_fun(attr){
    value=this.value;
    decimal = value.split('.');
    if ((decimal.length > 1 && decimal[1].length == 0) || (decimal.length == 1 && attr.keyCode == 110 && value.length == 3))
        $(this).attr('maxLength', this.value.length + 2);
    else if (decimal.length == 1)
        $(this).attr('maxLength', 3);
    $.isNumeric(value)  ? '' : $(this).val(value.substr(0,value.length - 3));
}

window.set_year_fun =  function set_year_fun(attr){
    value=$(attr).val();
    ($.isNumeric($(attr).val()) && value.length <= 2) ? '' : $(attr).val(value.substr(0,value.length-1))
}

window.get_month_year = function get_month_year(){
    var d = new Date();
    m = d.getMonth() + 1;
    if (m< 10)
      m= '0'+m;
    y = d.getFullYear();
    return m+ "-"+ y
}

String.prototype.replaceAt=function(index, character) {
    return this.substr(0, index) + character + this.substr(index+character.length);
}
$(function () {
    $("input[id*='user_year_as_medical_coder']").keydown(function (event) {


        if (event.shiftKey == true) {
            event.preventDefault();
        }

        if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 37 || event.keyCode == 39 ) {

        } else {
            event.preventDefault();
        }
    });

    $(".txt-new-yrs").keydown(function (event) {
        if (event.shiftKey == true) {
            event.preventDefault();
        }

        if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 37 || event.keyCode == 39 ) {

        } else {
            event.preventDefault();
        }
    });

});

window.make_aapc_as_selected = function make_aapc_as_selected() {
    $('.aapc_spec_list ul').empty();
    $('.aapc_spec_type').each(function(i, obj) {
        if ($(this).children().hasClass('image-on-small')){
            if($(this).children().find('input').val().indexOf("_selected") === -1)
                $(this).children().find('input').val($(this).children().find('input').val() + '_selected')
            list= $(this).children().text().trim().split('–')[1].trim().split(' ')[0];
            id = $(this).children().find('input').attr('id');
            aapc_list = '<li><span class="label label-info"> <a class="no_confirm_exit" href="javascript:void(0);" onclick="remove_aapc_spec_type(this);"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span> </a></span> </li>';
            $('.aapc_spec_list ul').append(aapc_list);
            $('.aapc_spec_list ul li').last().find('span').first().prepend(list);
            $('.aapc_spec_list ul li').last().attr('id', id);
        }
        else{
            $(this).children().find('input').val($(this).children().find('input').val().replace('_selected',''));
            $(this).children().find('input').trigger('change');
        }
        //test
    });
    if($('ul#new_aapc_spec_list>li').length > 0)
        tinysort("ul#new_aapc_spec_list>li",{attr:'id'});
}
