$(function () {
  window.confirm = function () {
    var e = window.event || window.confirm.caller.arguments[0];
    var el = e.target || e.srcElement; // the element's click that triggers confirm dialog
    showConfirmDialog(el);
  };

  $(document).on("click", "a[data-confirm]", function (e) {
    e.preventDefault();
    showConfirmDialog($(this));
  });

  function showConfirmDialog(link) {
    const message = link.data("confirm");
    const showYesNoBtn = link.data("btn-yes") !== undefined;

    const html = `
            <div class="modal" id="confirmationDialog" data-backdrop="static" data-keyboard="false">
                <div class="modal-dialog" style="width: 300px;">
                    <div class="modal-content">
                        <div class="modal-header">
                            <a class="close" data-dismiss="modal">×</a>
                            <h3 class="modal-title" style="font-size: 20px">Confirm Message</h3>
                        </div>
                        <div class="modal-body">
                            <p style="font-size: 16px;text-align: left">${message}</p>
                        </div>
                        <div class="modal-footer">
                            <a data-dismiss="modal" style="line-height: 35px; color: white" class="btn btm-sm-pri mrg-lft-2 cancel">Cancel</a>
                            <a data-dismiss="modal" style="line-height: 35px; color: white" class="btn btm-sm-pri-green mrg-lft-2 btn-primary confirm">OK</a>
                        </div>
                    </div>
                </div>
            </div>
        `;

    const modal = $(html).modal();
    updateBtnHtml(showYesNoBtn);
    modal.on("hidden.bs.modal", function () {
      modal.remove(); // Remove the modal from the DOM after it's closed
    });

    $("#confirmationDialog .confirm").click(function () {
      //confirmed(link);
      link.removeAttr("data-confirm");
      link[0].click();
    });
  }

  function updateBtnHtml(showYesNoBtn) {
    if (showYesNoBtn) {
      $("#confirmationDialog .confirm").html("Yes");
      $("#confirmationDialog .modal-footer").html(
        $("#confirmationDialog .modal-footer").find("a").get().reverse()
      );
    }
  }
});
