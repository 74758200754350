sendFileToServer = (formData, status, hosp_url, uploadURL) ->

  #Upload URL
  extraData = {}
  #Extra Data.
  jqXHR = $.ajax(
    xhr: ->
      xhrobj = $.ajaxSettings.xhr()
      if xhrobj.upload
        xhrobj.upload.addEventListener 'progress', ((event) ->
          percent = 0
          position = event.loaded or event.position
          total = event.total
          if event.lengthComputable
            percent = Math.ceil(position / total * 100)
          #Set progress
          status.setProgress percent
          return
        ), false
      xhrobj
    url: uploadURL
    type: 'POST'
    contentType: false
    processData: false
    cache: false
    data: formData
    success: (data) ->
      status.setProgress 100
      $('#status1').append 'File upload Done<br>'
      return
  )
  status.setAbort jqXHR
  return

createStatusbar = (obj) ->
  rowCount++
  row = 'odd'
  if rowCount % 2 == 0
    row = 'even'
  @statusbar = $('<div class=\'statusbar ' + row + '\'></div>')
  @filename = $('<div class=\'filename\'></div>').appendTo(@statusbar)
  @size = $('<div class=\'filesize\'></div>').appendTo(@statusbar)
  @progressBar = $('<div class=\'progressBar\'><div></div></div>').appendTo(@statusbar)
  @abort = $('<div class=\'abort\'>Abort</div>').appendTo(@statusbar)
  obj.after @statusbar

  @setFileNameSize = (name, size) ->
    sizeStr = ''
    sizeKB = size / 1024
    if parseInt(sizeKB) > 1024
      sizeMB = sizeKB / 1024
      sizeStr = sizeMB.toFixed(2) + ' MB'
    else
      sizeStr = sizeKB.toFixed(2) + ' KB'
    @filename.html name
    @size.html sizeStr
    return

  @setProgress = (progress) ->
    progressBarWidth = progress * @progressBar.width() / 100
    @progressBar.find('div').animate({ width: progressBarWidth }, 10).html progress + '% '
    if parseInt(progress) >= 100
      @abort.hide()
      @filename.hide()
      @size.hide()
      @progressBar.hide()
    return

  @setAbort = (jqxhr) ->
    sb = @statusbar
    @abort.click ->
      jqxhr.abort()
      sb.hide()
      return
    return

  return

handleFileUpload1 = (files, obj) ->
  i = 0
  while i < 1
    fd = new FormData
    fd.append 'file', files[i]
    status = new createStatusbar(obj)
    #Using this we can set progress.
    status.setFileNameSize files[i].name, files[i].size
    uploadURL = $('#bba_doc_url').val()
    sendFileToServer fd, status, $('#hospital_url').val(), uploadURL
    i++
  return

handleFileUpload2 = (files, obj) ->
  i = 0
  while i < 1
    fd = new FormData
    fd.append 'file', files[i]
    status = new createStatusbar(obj)
    #Using this we can set progress.
    status.setFileNameSize files[i].name, files[i].size
    uploadURL = $('#policy_doc_url').val()
    sendFileToServer fd, status, $('#hospital_url').val(), uploadURL
    i++
  return

$(document).on 'turbolinks:load', ->
  cropzoom = $('#crop_container').cropzoom(
    width: 300
    height: 300
    bgColor: '#CCC'
    enableRotation: false
    enableDrag: false
    enableZoom: false
    zoomSteps: 10
    rotationSteps: 10
    enableRotation: false
    enableZoom: false
    selector:
      centered: false
      startWithOverlay: true
      borderColor: 'blue'
      borderColorHover: 'red   '
    image:
      source: $('.img-responsive').attr('src')
      width: 768
      height: 768
      minZoom: 20
      onRotate: (imageObject, rotate_angle) ->
# Get rotatation angle
        $('#rotation_angle').val rotate_angle
        return
      onZoom: (imageObject, dimensions) ->
# Get zoom coordinates
        $('#zoom_w').val dimensions.w
        $('#zoom_h').val dimensions.h
        $('#zoom_x').val dimensions.posX
        $('#zoom_y').val dimensions.posY
        # Set drag coordinates to zero when image is zoomed
        $('#drag_x').val 0
        $('#drag_y').val 0
        return
      onImageDrag: (imageObject, position) ->
# Get dragged image coordinates
        $('#drag_x').val position.posX
        $('#drag_y').val position.posY
        # Set zoom x-y coordinates to zero when image is dragged
        $('#zoom_x').val 0
        $('#zoom_y').val 0
        return
  )
  return
$(document).on 'click', 'input[type="submit"]', ->
  if($('#infoSelector') && $('#infoSelector')[0])
      get_html = $('#infoSelector').html()
      get_array = get_html.split('|')
      get_x_y_coords_array = get_array[0].split('-')
      get_w_h_coords_array = get_array[1].split('-')
      get_x_coord = get_x_y_coords_array[0].split(':')[1]
      get_y_coord = get_x_y_coords_array[1].split(':')[1]
      get_w_coord = get_w_h_coords_array[0].split(':')[1]
      get_h_coord = get_w_h_coords_array[1].split(':')[1]
      $('#crop_x').val get_x_coord
      $('#crop_y').val get_y_coord
      $('#crop_w').val get_w_coord
      $('#crop_h').val get_h_coord
  return
rowCount = 0
$(document).on 'turbolinks:load', ->
  obj1 = $('#dragandrophandler_bba')
  obj1.on 'dragenter', (e) ->
    e.stopPropagation()
    e.preventDefault()
    $(this).css 'border', '2px solid #0B85A1'
    return
  obj1.on 'dragover', (e) ->
    e.stopPropagation()
    e.preventDefault()
    return
  obj1.on 'drop', (e) ->
    $(this).css 'border', '2px dotted #0B85A1'
    e.preventDefault()
    files = e.originalEvent.dataTransfer.files
    #We need to send dropped files to Server
    handleFileUpload1 files, obj1
    return
  $(document).on 'dragenter', (e) ->
    e.stopPropagation()
    e.preventDefault()
    return
  $(document).on 'dragover', (e) ->
    e.stopPropagation()
    e.preventDefault()
    obj1.css 'border', '2px dotted #0B85A1'
    return
  $(document).on 'drop', (e) ->
    e.stopPropagation()
    e.preventDefault()
    return
  return
$(document).on 'turbolinks:load', ->
  obj2 = $('#dragandrophandler_policy')
  obj2.on 'dragenter', (e) ->
    e.stopPropagation()
    e.preventDefault()
    $(this).css 'border', '2px solid #0B85A1'
    return
  obj2.on 'dragover', (e) ->
    e.stopPropagation()
    e.preventDefault()
    return
  obj2.on 'drop', (e) ->
    $(this).css 'border', '2px dotted #0B85A1'
    e.preventDefault()
    files = e.originalEvent.dataTransfer.files
    #We need to send dropped files to Server
    handleFileUpload2 files, obj2
    return
  $(document).on 'dragenter', (e) ->
    e.stopPropagation()
    e.preventDefault()
    return
  $(document).on 'dragover', (e) ->
    e.stopPropagation()
    e.preventDefault()
    obj2.css 'border', '2px dotted #0B85A1'
    return
  $(document).on 'drop', (e) ->
    e.stopPropagation()
    e.preventDefault()
    return
  return
# ---
# generated by js2coffee 2.0.4
