$(document).on("turbolinks:load", function () {
  var profileFile = "";

  $("#bs-example-modal-sm").on("show.bs.modal", function () {
    $("form").find("input .tooltipstered").tooltipster("hide");
  });

  if ($('#dragAndDropHandler').length > 0) {
    let obj = $('#dragAndDropHandler')
    obj.on('dragenter', function(e) {
      e.stopPropagation();
      e.preventDefault();
      $(this).css('border', '2px solid #0B85A1');
    });
    obj.on('dragover', function(e) {
      e.stopPropagation();
      e.preventDefault();
    });
    obj.on('drop', function(e) {
      var files;
      $(this).css('border', '2px dotted #0B85A1');
      e.preventDefault();
      files = e.originalEvent.dataTransfer.files;
      handleFileUpload(files, obj);
    });
    $(document).on('dragenter', function(e) {
      e.stopPropagation();
      e.preventDefault();
    });
    $(document).on('dragover', function(e) {
      e.stopPropagation();
      e.preventDefault();
      obj.css('border', '2px dotted #0B85A1');
    });
    $(document).on('drop', function(e) {
      e.stopPropagation();
      e.preventDefault();
    });
  }

  function handleFileUpload(files, obj) {
    profileFile = files[0];
    NProgress.start();
    $('#image_form').submit();
  }

  $('#user_avatar').change(function() {


    let input = this;
    if (input.files && input.files[0]) {
      profileFile = input.files[0];
      NProgress.configure({
        trickle: false,
        parent: "#imagePreview1",
        showSpinner: true
      });
      NProgress.start();
      $('#image_form').submit();
    }
  });

  $('#image_form').submit(function(e) {
    e.preventDefault();

    if(profileFile == ""){
      return;
    }
    const postUrl = `${$(this).attr('action')}?hospital_url=${$("#hospital_url").val()}`
    let input = $('#user_avatar');
    var formData = new FormData();
    formData.append("id", input.data("user_id"));

    if ($("#hospital_url").val() == "true"){
      formData.append("hospital[avatar]", profileFile);
    } else {
      formData.append("user[avatar]", profileFile);
    }

    $.ajax({
      url: postUrl,
      type: 'POST',
      data: formData,
      dataType: 'script',
      processData: false,
      contentType: false,
      success: function(response) {},
      error: function(xhr, status, error) {
        // Handle error response
        console.error(xhr.responseText);
      }
    });
  });
});
