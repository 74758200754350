export var HospitalJobPostingRules  = {
  rateValidation: function(){
    var icd10Checked = is_coding_type_checked('ICD10');
    var isInpatientChecked = is_patient_type_checked('IN');
    var hourly_checked = $("#rb_hourly").is(":checked");
    var chart_checked = $("#rb_perchart").is(":checked");
    var hourly_rate = parseFloat($("#hospital_job_per_hour").val());
    var per_chart_rate = parseFloat($("#hospital_job_fee_per_chart").val());

    var ruleNotPassed = icd10Checked
      && isInpatientChecked
      && ((hourly_checked && hourly_rate<40) || (chart_checked && per_chart_rate < 26));


    if (ruleNotPassed)
      $(".rate_lbl").show();
    else
      $(".rate_lbl").hide();
  },

  yearsValidation: function(){
    var opdSimpleChecked = is_patient_type_checked('OPDS');
    var edWithoutEMChecked = is_patient_type_checked('EDWOEM');
    var coderExperience = parseInt($("#coder_experience").val());

    var ruleNotPassed = (opdSimpleChecked
      || edWithoutEMChecked)
      && (coderExperience > 4);


    if (ruleNotPassed)
      $(".years_lbl").show();
    else
      $(".years_lbl").hide();
  },

  chartsValidation: function(){
    var start_new_date =  get_new_date($("#hospital_job_start_date").val());
    var end_new_date = get_new_date($("#hospital_job_end_date").val());

    var daysDiff = Math.ceil((end_new_date - start_new_date + 1) / (1000 * 60 * 60 * 24));

    var icd10Checked = is_coding_type_checked('ICD10');
    var isInpatientChecked = is_patient_type_checked('IN');
    var chart_checked = $("#rb_perchart").is(":checked");
    var number_of_charts = parseFloat($("#hospital_job_no_of_chart").val());

    var ruleNotPassed = icd10Checked
      && isInpatientChecked
      && chart_checked
      && number_of_charts > (20 * daysDiff);

    if (ruleNotPassed){
      $(".workload_lbl").show();
    }else{
      $(".workload_lbl").hide();
    }
  },

  credValidation: function(){
    var icd10Checked = is_coding_type_checked('ICD10');
    var isInpatientChecked = is_patient_type_checked('IN');
    var opComplexChecked = is_patient_type_checked('OPDC');
    var edWithEmAndInj = is_patient_type_checked('EDWEMII');

    let rhitChecked = is_certification_checked('RHIT');
    let rhiaChecked = is_certification_checked('RHIA');
    let ccsChecked = is_certification_checked('CCS');

    var ruleNotPassed = (icd10Checked && (isInpatientChecked || opComplexChecked || edWithEmAndInj))
      && !(rhitChecked || rhiaChecked || ccsChecked);

    if (ruleNotPassed){
      $(".cred_inpt_lbl").show();
    }else{
      $(".cred_inpt_lbl").hide();
    }
  },

  circcValidation: function(){
    var opComplexChecked = is_patient_type_checked('IRC');
    var circcChecked = is_speciality_checked('CIRCC');

    var ruleNotPassed = opComplexChecked && !circcChecked

    if (ruleNotPassed){
      $(".circc_lbl").show();
    }else{
      $(".circc_lbl").hide();
    }
  },


  runAllValidations: function(){
    try{
      if ($("#chkValidations").is(":checked")){
        HospitalJobPostingRules.rateValidation();
        HospitalJobPostingRules.yearsValidation();
        HospitalJobPostingRules.chartsValidation();
        HospitalJobPostingRules.credValidation();
        HospitalJobPostingRules.circcValidation();
      }else{
        $(".validation-span").hide();
      }
    }
    catch(e){}
  }
};

$(document).ready(function(){
  setInterval(HospitalJobPostingRules.runAllValidations, 300);
});

