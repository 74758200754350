// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
window.originalConfirm = window.confirm;

import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "jquery";
import 'jquery-ui';
import 'jquery-validation';
import 'bootstrap-datepicker';
import 'turbolinks';
import 'nprogress';
import 'dropzone';
import 'jquery-validation';
import './unsaved_changes.js';


//Custom Files
import './cocoon.js';
import tinysort from './tinysort.min.js';
import './custom_copy';
import './jquery.cropzoom';
import './jquery.confirmExit.min';
import './jquery.confirm.min.js';
import './jquery-html5-placeholder-shim';
import './jquery.raty';
import './jquery.tooltipster.min';
import './jquery.autocomplete';
import './bootstrap.min';
import './bootstrap-slider';
import './bootstrap-select';
import './select2';
import './select2.min';
import './custom'; // multiselect in not a function Error giving in jquery
import './custom-extended';
import './bootstrap-show-password';
import './hospital'; // multiselect in not a function Error giving in jquery
import './validation_regex';
import './coder_dashboard'; 
import './set_status';
import './set_star_ratings';
import './company';
import './confirm_alert';
import './hospital_job_posting_rules';
import './hospital_job_posting';
import './bootstrap.min';
import './additional-methods.min'; 
import './user_registrations/sign_up_form.js'
import './user_steps/work_preference.js'
import './user_steps/profile_photo_upload.js'

//Coffee Script files 
import './users.js.coffee';
import './image_load.js.coffee';
//import './custom2.js.coffee'; //Example File

// import 'jquery.remotipart'; //not avaliable in yarn and npm coming from remotipart gem
// import 'nested_form_fields'; //not avaliable in yarn and npm coming from nested_form_fields gem



window.jQuery = $;
window.$ = $;
window.tinysort = tinysort;
Rails.start()
Turbolinks.start()
ActiveStorage.start()

document.addEventListener('ajax:error', function(event) {
  var xhr = event.detail[1];
  if (xhr === "Unauthorized") {
    window.location.href = "/users/sign_in?timeout=true";
  }
});

var idleTime = 0;
$(document).ready(function () {
  // Increment the idle time counter every minute.
  if($("#autoTimeout").length == 0) {
    setInterval(timerIncrement, 60000); // 1 minute
    // Zero the idle timer on any action.
    $(document).bind('keydown scroll click', function (e) {
        idleTime = 0;
    });
  }
});

function timerIncrement() {
  idleTime++;
  // Idle time = 30 minutes (AS PER DEVISE CONFIG)
  if (idleTime > 30){
    window.location.href = "/users/sign_in?timeout=true";
  }
}