/*! =========================================================
 * set_star_ratings.js
 *
 * Custom rating functions:
 * ========================================================= */

window.setStarRatings = function setStarRatings() {
    $('#quality').raty({
        path: '/assets',
        scoreName: 'quality_score',
        target     : '#quality_rating',
        targetKeep : true,
        targetType : 'score',
        click: function(score, evt) {
            setScore('quality_rating');
            calculateTotalScore();            
        }
    });
    $('#timeliness').raty({
        path: '/assets',
        scoreName: 'timeliness_score',
        target     : '#timeliness_rating',
        targetKeep : true,
        targetType : 'score',
        click: function(score, evt) {
            setScore('timeliness_rating');
            calculateTotalScore();
        }
    });
    $('#knowledge').raty({
        path: '/assets',
        scoreName: 'knowledge_score',
        target     : '#knowledge_rating',
        targetKeep : true,
        targetType : 'score',
        click: function(score, evt) {
            setScore('knowledge_rating');
            calculateTotalScore();
        }
    });
    $('#effectiveness').raty({
        path: '/assets',
        scoreName: 'effectiveness_score',
        target     : '#effectiveness_rating',
        targetKeep : true,
        targetType : 'score',
        click: function(score, evt) {
            setScore('effectiveness_rating');
            calculateTotalScore();
        }
    });
    $('#professional').raty({
        path: '/assets',
        scoreName: 'professional_score',
        target     : '#professional_rating',
        targetKeep : true,
        targetType : 'score',
        click: function(score, evt) {
            setScore('professional_rating');
            calculateTotalScore();
        }
    });
}

window.showSavedRatings = function showSavedRatings() {
    $('#quality').raty({
        path: '/assets',
        scoreName: 'quality_score',
        target     : '#quality_rating',
        targetKeep : true,
        targetType : 'score',
        score: $('#quality_rating').val(),
        click: function(score, evt) {
            setScore('quality_rating');
            calculateTotalScore();
        }
    });
    $('#timeliness').raty({
        path: '/assets',
        scoreName: 'timeliness_score',
        target     : '#timeliness_rating',
        targetKeep : true,
        targetType : 'score',
        score: $('#timeliness_rating').val(),
        click: function(score, evt) {
            setScore('timeliness_rating');
            calculateTotalScore();
        }
    });
    $('#knowledge').raty({
        path: '/assets',
        scoreName: 'knowledge_score',
        target     : '#knowledge_rating',
        targetKeep : true,
        targetType : 'score',
        score: $('#knowledge_rating').val(),
        click: function(score, evt) {
            setScore('knowledge_rating');
            calculateTotalScore();
        }
    });
    $('#effectiveness').raty({
        path: '/assets',
        scoreName: 'effectiveness_score',
        target     : '#effectiveness_rating',
        targetKeep : true,
        targetType : 'score',
        score: $('#effectiveness_rating').val(),
        click: function(score, evt) {
            setScore('effectiveness_rating');
            calculateTotalScore();
        }
    });
    $('#professional').raty({
        path: '/assets',
        scoreName: 'professional_score',
        target     : '#professional_rating',
        targetKeep : true,
        targetType : 'score',
        score: $('#professional_rating').val(),
        click: function(score, evt) {
            setScore('professional_rating');
            calculateTotalScore();
        }
    });
}

window.setScore = function setScore(id) {
    var score = parseFloat($('#' + id).val()).toFixed(2);
    $('#'+ id).val(score);
}

window.calculateTotalScore = function calculateTotalScore() {
    var ids = ['quality_rating', 'timeliness_rating', 'knowledge_rating',
    'effectiveness_rating','professional_rating'];
    var totalScore = findAverage(ids);
    $('#total_score').html('Total Score : '+ totalScore);
    $('#rating').val(totalScore);
    $('#total_score').attr('class', 'alert alert-info');
}

window.findAverage = function findAverage(array) {
    var sum = 0;
    var count = 0;
    var value;
    for(var i = 0; i < array.length; i++) {
        sum = sum + convertToNumber(array[i]);
        value = $('#' + array[i]).val();
        if(value != '') {
            count++;
        }
    }
    var average = ((parseFloat(sum)) / count);
    return parseFloat(average).toFixed(2);
}

window.convertToNumber = function convertToNumber(id) {
    var result = 0;
    var value = $('#' + id).val();
    if(value != '') {
        result = parseFloat(value);

    }
    return result;
}

window.viewStarRatings = function viewStarRatings() {
    $('#5star').raty('destroy');
    $('#4star').raty('destroy');
    $('#3star').raty('destroy');
    $('#2star').raty('destroy');
    $('#1star').raty('destroy');

    $('#5star').raty({
        path: '/assets',
        score : 5,
        readOnly: true
    });
    $('#4star').raty({
        path: '/assets',
        score : 4,
        readOnly: true
    });
    $('#3star').raty({
        path: '/assets',
        score : 3,
        readOnly: true
    });
    $('#2star').raty({
        path: '/assets',
        score : 2,
        readOnly: true
    });
    $('#1star').raty({
        path: '/assets',
        score : 1,
        readOnly: true
    });
}

window.createRatings = function createRatings() {
    saveRatings('/user_ratings', 'post');
}

window.updateRatings = function updateRatings(id) {
    saveRatings('/user_ratings/' + id, 'put');
}

window.saveRatings = function saveRatings(url, method) {
    var result = false;
    $("#submit_button_id").click(function() {
        if($("#modal_alert_message") && $("#modal_alert_message")[0]) {
            $("#modal_alert_message").html('') ;
        }
        if($('#rating').val() != '' && $('#rating').val() != '0.0') {
            var confirmed = true;
            if(parseFloat($('#rating').val() ) < 3) {
                confirmed = originalConfirm('You are about to give low score to the coder. Are you sure?');
            }
            if(confirmed) {
                var str = $('#rating_form').serialize();
                $.ajax({
                    method: method,
                    url: url,
                    data: str,
                    success: function (response) {
                        if(response != false && response != 'false') {
                            if($("#modal_alert_message") && $("#modal_alert_message")[0]) {
                                $("#modal_alert_message").attr('class', 'alert alert-info');
                                $("#modal_alert_message").html('Rating is successfully saved') ;
                            }
                            if($('#index') && $('#index')[0]) {
                                var index = $('#index').val();
                                var recordId = '#user_rating_id_' + index;
                                if($(recordId) && $(recordId)[0]) {
                                    $(recordId).val(response);
                                }
                                var createStarId = '#create_rating_' + index;
                                var shadowEditId = '#shadow_edit_rating_' + index;
                                if($(createStarId) && $(createStarId)[0] && $(shadowEditId) && $(shadowEditId)[0]) {
                                    if($(shadowEditId).css('display') == 'none') {
                                        $(createStarId).toggle();
                                        $(shadowEditId).toggle();
                                    }
                                    if($(createStarId).css('display') == 'block') {

                                        $(shadowEditId).toggle();
                                    }
                                }
                            }
                        }
                        else {
                            if($("#modal_alert_message") && $("#modal_alert_message")[0]) {
                                $("#modal_alert_message").attr('class', 'alert alert-info red-color');
                                $("#modal_alert_message").html('You cannot edit since the previous score was less than 3.');
                            }
                        }
                    }
                });
            }
        }
        else {
            if($("#modal_alert_message") && $("#modal_alert_message")[0]) {
                $("#modal_alert_message").attr('class', 'alert alert-info red-color');
                $("#modal_alert_message").html('Please choose the rating to submit.') ;
            }
        }
        setTimeout(function(){
            hideAlert();
        }, 5000);
        return result;
    });

}

window.showEditUserRating = function showEditUserRating(index) {
    if($('#user_rating_id_' + index)) {
        var id = $('#user_rating_id_' + index).val();
        $.ajax({
            method: 'get',
            url: '/user_ratings/' + id + '/edit',
            success: function (response) {
            }
        });
    }
}

window.makeFavourite = function makeFavourite(user_id, hospital_user_id, hospital_id) {
    var result = false;
    $('#make_favourite_id').click(function() {
        var params = 'user_id=' + user_id + '&hospital_user_id=' + hospital_user_id +
        '&hospital_id=' + hospital_id;
        $.ajax({
            method: 'post',
            url: '/favourite_coders',
            data: params,
            success: function (response) {
                if(response != '') {
                    $('#make_favourite_div').attr('style', 'display:none') ;
                    $('#remove_favourite_div').attr('style', 'display:block') ;
                    $('#favourite_coder_id').val(response);
                }
            }
        });

        return result;
    });
}

window.removeFavourite = function removeFavourite() {
    var result = false;
    $('#remove_favourite_id').click(function() {
        if($('#favourite_coder_id') && $('#favourite_coder_id').val() != '') {
            $.ajax({
                method: 'delete',
                url: '/favourite_coders/' + $('#favourite_coder_id').val(),
                success: function (response) {
                    if(response == true || response == 'true') {
                        result = true;
                        $('#remove_favourite_div').attr('style', 'display:none') ;
                        $('#make_favourite_div').attr('style', 'display:block') ;
                        $('#favourite_coder_id').val('');
                    }
                }
            });
        }
        return result;
    });
}

window.afterDelete = function afterDelete(index) {
    if($('#record_' + index) && $('#record_' + index)[0]) {
        $('#record_' + index).toggle();
        if($("#alert_message") && $("#alert_message")[0]) {
            $("#alert_message").attr('class', 'alert alert-info');
            $("#alert_message").html('You have successfully deleted.') ;
        }
    }
    else if($('#favourite_coder_id') && $('#favourite_coder_id')[0]){
        $('#remove_favourite_div').attr('style', 'display:none') ;
        $('#make_favourite_div').attr('style', 'display:block') ;
        $('#favourite_coder_id').val('');
    }
}

window.showCoderRatings = function showCoderRatings() {
    $('#coder_star').raty({
        path: '/assets',
        score : $('#coder_rating').val(),
        readOnly: true
    });

    $('#coder_star').raty({
      path: '/assets',
      score : $('#coder_rating').val(),
      readOnly: true
    });
}

window.showMultipleCoderRatings = function showMultipleCoderRatings() {
    for(var i = 0; i < 10; i++) {
        $('#coder_star' + i).raty({
            path: '/assets',
            score : $('#coder_rating' + i).val(),
            readOnly: true
        });
    }

    for(var i = 0; i < 10; i++) {
      $('#top_matches_star' + i).raty({
        path: '/assets',
        score : $('#top_matches_rating' + i).val(),
        readOnly: true
      });
    }
}
