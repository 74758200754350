$(document).on('turbolinks:load', function() {
    typeInQuestion();
    showJobDetails(true);
    askHospital(true);
});

window.showJobDetails = function showJobDetails(value) {
    $("#showdet").click(function(){
        $(".job-det").toggle(200);
        if(!value) {
            changeCheckBoxImageClass('showdet');
        }
    });
}

window.typeInQuestion = function typeInQuestion() {
    $('.coder_ask_question').keyup(function () {
        if( $(this).val().trim() == '')
            $(this).val($(this).val().trim());
        if( $(this).val().length > 0)
            $('.post_question_btn').prop('disabled', false);
        else
            $('.post_question_btn').prop('disabled', true);
        var left = 450 - $(this).val().length;
        if (left < 0) {
            left = 0;
        }
        $('.chr-lft').text(left+ ' characters left');
    });
}

window.askHospital = function askHospital(value) {
    $('.post_question_btn').click(function(){
        var data = {
            'hospital_id': $('#hospital_id').val(),
            'job_id': $('#job_id').val(),
            subject: $('.coder_ask_question').val()
        };
        if(value) {
            $('#email_send_modal').modal('hide');
        }
        $.ajax({
            url: "/coder_dashboard/ask_question",
            type: "GET",
            data: data,
            contentType: 'application/json; charset=utf-8',
            dataType: 'json',
            success: function (data) {
                if($("#posted_question") && $("#posted_question")[0]) {
                    $("#posted_question").val(true);
                }
                if(value) {
                    $('#email_send_modal').modal('show');
                }
                else {
                    if($("#modal_alert_message") && $("#modal_alert_message")[0]) {
                        $("#modal_alert_message").attr('class', 'alert alert-info');
                        $("#modal_alert_message").html('Your email was sent.') ;
                    }
                }
                $('.coder_ask_question').val('');
                $('.chr-lft').text('450 characters left');
                $("#showdet").removeClass('image-on');
                $(".job-det").hide();
                $('.post_question_btn').prop('disabled', true);
                setTimeout(function(){
                    hideAlert();
                }, 5000);
            },
            error: function () {
            }
        });

    })
}


window.askCoder = function askCoder(value) {
    $('.post_question_btn').click(function(){
        var data = {
            'coder_id': $('#coder_id').val(),
            'job_id': $('#job_id').val(),
            subject: $('.coder_ask_question').val()
        };
        if(value) {
            $('#email_send_modal').modal('hide');
        }
        $.ajax({
            url: "/hospital_dashboard/ask_coder",
            type: "GET",
            data: data,
            contentType: 'application/json; charset=utf-8',
            dataType: 'json',
            success: function (data) {                
                if($("#posted_question") && $("#posted_question")[0]) {
                    $("#posted_question").val(true);
                }
                if(value) {
                    $('#email_send_modal').modal('show');
                }
                else {
                    if($("#modal_alert_message") && $("#modal_alert_message")[0]) {
                        $("#modal_alert_message").attr('class', 'alert alert-info');
                        $("#modal_alert_message").html('Your email was sent.') ;
                    }
                }
                $('.coder_ask_question').val('');
                $('.chr-lft').text('450 characters left');
                $("#showdet").removeClass('image-on');
                $(".job-det").hide();
                $('.post_question_btn').prop('disabled', true);
                setTimeout(function(){
                    hideAlert();
                }, 5000);
                             
            },
            error: function () {
            }
        });

    })
}

window.openReviewContract = function openReviewContract() {
    $('#ask_question_modal').on('hidden.bs.modal', function (e) {
        var index = $('#index').val();
        var id = $('#hospital_job_id_' + index).val();
        var params = 'index=' + index + '&tab=' + 'posted';
        var urlHash = {}
        urlHash.params = params;
        urlHash.url = '/hospital_jobs/' + id + '/review_contract';
        getPartial(urlHash);
    })
}

window.openReviewJob = function openReviewJob() {
    $('#ask_question_modal').on('hidden.bs.modal', function (e) {        
        var index = $('#index').val();
        var id = $('#hospital_job_id_' + index).val();
        var params = 'index=' + index + '&tab=' + 'contracted';
        var urlHash = {}
        urlHash.params = params;
        urlHash.url = '/hospital_jobs/' + id +  '/submit_job_form';    
        getPartial(urlHash);
    })
}

window.openSubmitJob = function openSubmitJob() {
    $('#ask_question_modal').on('hidden.bs.modal', function (e) {
        var index = $('#index').val();
        var id = $('#user_hospital_job_id_' + index).val();
        var params = 'index=' + index + '&tab=' + 'inqueue';
        var urlHash = {}
        urlHash.params = params;
        urlHash.url = '/user_hospital_jobs/' + id +  '/submit_job_form';
        getPartial(urlHash);
    })
}

window.openViewComment = function openViewComment() {
    $('#ask_question_modal').on('hidden.bs.modal', function (e) {
        var index = $('#index').val();
        var id = $('#user_hospital_job_id_' + index).val();
        var params = 'index=' + index + '&tab=' + 'inqueue';
        var urlHash = {}
        urlHash.params = params;
        urlHash.url = '/user_hospital_jobs/' + id +  '/view_comment';
        getPartial(urlHash);
    })
}

window.getPartial = function getPartial(urlHash) {
    $.ajax({
        method: 'get',
        url: urlHash.url,
        data: urlHash.params,
        success: function (response) {
            if($("#posted_question") && $("#posted_question")[0]) {
                if($("#posted_question").val()) {
                    if($("#modal_alert_message") && $("#modal_alert_message")[0]) {
                        $("#modal_alert_message").attr('class', 'alert alert-info');
                        $("#modal_alert_message").html('Your email was sent.') ;
                        setTimeout(function(){
                            hideAlert();
                        }, 5000);
                    }
                    $("#posted_question").val(false);
                }
            }
        }
    });
}

window.showReasons = function showReasons(bottomIcon, upIcon, reasonDiv, commentsDiv) {
    if($('#' + bottomIcon) && $('#' + bottomIcon)[0]) {
        $('#' + bottomIcon).toggle();
    }
    if($('#' + upIcon) && $('#' + upIcon)[0]) {
        $('#' + upIcon).toggle();
    }
    if($('#' + reasonDiv) && $('#' + reasonDiv)[0]) {
        $('#' + reasonDiv).toggle();
    }
    if($('#' + commentsDiv) && $('#' + commentsDiv)[0]) {
        $('#' + commentsDiv).toggle();
    }
}

window.closePostQuestion = function closePostQuestion() {
    $('.coder_ask_question').val('');
    $('.chr-lft').text('450 characters left');
    $("#showdet").removeClass('image-on').addClass('image-off');
    $(".job-det").hide();
    $('.post_question_btn').prop('disabled', true);
    $('#post_question').toggle();
}
